import React from 'react';
import DocumentMeta from 'react-document-meta';
import {
  CssBaseline,
  Box,
  Container,
  createTheme,
  ThemeProvider,
  Button,
  Grid,
  Dialog,
  Select,
  Switch,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Tooltip,
  ButtonGroup,
  Card,
  CardContent,
  Typography,
  CardActions,
  Avatar,
  Divider
  // TextField
} from '@mui/material';
import { HashLink } from 'react-router-hash-link';

import NotAvailable from "../../assets/images/not_available.jpg";
import MyprofileBasicdetails from '../../components/myprofile/basicdetails/myprofile/basicdetails';
import MyprofileLeaselistings from '../../components/myprofile/leaselistings/myprofile/leaselistings';
import MyprofileProductlistings from '../../components/myprofile/productlistings/myprofile/productlistings';
import MyprofileFamilyplans from '../../components/myprofile/familyplans/myprofile/familyplans';
import MyprofileTrips from '../../components/myprofile/trips/myprofile/trips';

const theme = createTheme();
const meta = {
  title: 'HousingNet | Profile',
  description: 'View and update data related to you!',
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags,housing'
    }
  }
};

const MyprofilePage = () => {
  return (
    <DocumentMeta {...meta}>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={3}>
              <Card sx={{ maxWidth: 200 }}>
                <Avatar alt="Mandeep Vratesh" src={NotAvailable} sx={{ width: 150, height: 150, margin: 1 }}/>
                <CardContent>
                  <Typography gutterBottom variant="h6">
                    Mandeep Vratesh
                  </Typography>
                </CardContent>
                <CardActions>
                  <div style={{flex: '1 0 0'}} />
                  <Button
                    variant="outlined"
                    size="small"
                    rel="noopener"
                    target="_blank"
                    href="https://www.linkedin.com/in/steffythampy"
                  >
                    Linkedin
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    rel="noopener"
                    target="_blank"
                    href="mailto:steffy.thampy@gmail.com"
                  >
                    Email
                  </Button>
                </CardActions>
              </Card>
            </Grid> */}
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{color: "#0561B8FF"}} variant="h4">
                  My Profile
                </Typography>
                <Typography sx={{color: "#0561B8FF"}} variant="h6">
                  (Visible to only you unless shared specifically.)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{display: { xs: 'none', md: 'inline-flex' }}}>
                  <HashLink smooth to="/myprofile#basicdetails" style={{ textDecoration: 'none' }}>
                    <Button>Basic Details</Button>
                  </HashLink>
                  <HashLink smooth to="/myprofile#myleases" style={{ textDecoration: 'none' }}>
                    <Button>My Leases</Button>
                  </HashLink>
                  <HashLink smooth to="/myprofile#myproducts" style={{ textDecoration: 'none' }}>
                    <Button>Products</Button>
                  </HashLink>
                  <HashLink smooth to="/myprofile#myfamilyplan" style={{ textDecoration: 'none' }}>
                    <Button>Family Plans</Button>
                  </HashLink>
                  <HashLink smooth to="/myprofile#mytrips" style={{ textDecoration: 'none' }}>
                    <Button>My Trips</Button>
                  </HashLink>
                </ButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <MyprofileBasicdetails />
                <Divider />
                <MyprofileLeaselistings />
                <Divider />
                <MyprofileProductlistings />
                <Divider />
                <MyprofileFamilyplans />
                <Divider />
                <MyprofileTrips />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
    </DocumentMeta>
  );
}

export default MyprofilePage;
