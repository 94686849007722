import React from 'react';

const ShowWhenAuthenticated = ({children, authrequire}) => {
    // check from local storage
    const userInfo = JSON.parse(sessionStorage.getItem("user-info"));

    return(
        <>
            { authrequire == null ? children : (authrequire && userInfo ? children : (!authrequire && !userInfo ? children : null)) }
        </>
    );
}

export default ShowWhenAuthenticated;
