import React, { useState, useEffect } from 'react';
import DocumentMeta from 'react-document-meta';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { loginUser, addTracker, resetPassword } from '../../globals/firebaseConfigs';
import { useNavigate } from "react-router-dom";
import { BLOCKED_EMAILS } from '../../globals/constants';
import { validateEmail, getUserFriendlyAuthError } from '../../globals/utils';
import LottieLoading from '../../components/lottie/loading/lottie/loading';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const theme = createTheme();
const meta = {
  title: 'HousingNet | Login',
  description: 'Join the community!',
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags,housing,leasing'
    }
  }
};

export default function Loginpage() {

  const [message, setMessage] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openResetPasswordDialog, toggleResetPasswordDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => addTracker('loginpage'));

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    if (!BLOCKED_EMAILS.includes(email)) {
      loginUser(email, password).then((userCredential) => {
        // Signed in successfully
        setMessage('Successfully logged in!');
        navigate('/');
      })
        .catch((error) => {
          setMessage(getUserFriendlyAuthError(error.code));
        });
    } else {
      alert('This email is blocked due to suspecious activities! Please send an email to mandeepv@buffalo.edu if you think you were blocked incorrectly.');
    }
  };

  const handleResetPassword = () => {
    setWaiting(true);

    if (validateEmail(email))
      resetPassword(email).then(res => {
        if (res == true) {
          setMessage('Password reset email sent! Please check inbox -> reset password -> come back and login.');
        } else {
          setMessage(getUserFriendlyAuthError(res));
        }
        setWaiting(false);
      });
    else
      setMessage('Not a valid email, please use university email');
  }

  return (
    <DocumentMeta {...meta}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <Typography component="h1" variant="h6" color="error" textAlign="center">
                {message}
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={() => toggleResetPasswordDialog(true)}>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
        <Dialog open={openResetPasswordDialog} onClose={() => toggleResetPasswordDialog(false)}>
          <DialogTitle>Please confirm your email:</DialogTitle>
          <DialogContent>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <Typography component="h1" variant="h6" color="error" textAlign="center">
              {message}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleResetPasswordDialog(false)}>Cancel</Button>
            <Button onClick={handleResetPassword} autoFocus>Send Password Reset Email</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={waiting}
          aria-describedby="alert-dialog-slide-description"
        >
          <LottieLoading message={message} />
        </Dialog>
      </ThemeProvider>
    </DocumentMeta>
  );
}