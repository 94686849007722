import { createSlice } from '@reduxjs/toolkit'

export const cacheSlice = createSlice({
  name: 'cache',
  initialState: {
    landlords: [],
    propertyopts: {}
  },
  reducers: {
    updatePropertyOptions: (state, action) => {
      state.value = action.payload;
    },
    updateLandlords: (state, action) => {
      state.value = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updatePropertyOptions, updateLandlords } = cacheSlice.actions

export default cacheSlice.reducer;