import React from 'react';
import styles from './pay-rent.module.css';

import Lottie from "lottie-react";
import payrentAnimation from "./128864-character-jumping.json";

const LottiePayRent = () => (
  <div className={styles.LottiePayRent} data-testid="LottiePayRent">
    <Lottie animationData={payrentAnimation} loop={true} />
  </div>
);

LottiePayRent.propTypes = {};

LottiePayRent.defaultProps = {};

export default LottiePayRent;
