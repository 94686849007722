import React from 'react'
import './App.css';
import AppRouter from './routers/AppRouter';
import FeedbackForm from './components/feedback-form/feedback-form';

// import { useDispatch } from 'react-redux';
// import { updatePropertyOptions } from "./redux/cacheSlice";
// import { getPropertyOptions } from './globals/firebaseConfigs';

function App() {

  // // cache data from firebase
  // const dispatch = useDispatch();

  // await getPropertyOptions().then((snap) => {
  //   console.log(snap.val());
  //   dispatch(updatePropertyOptions(snap.val()));
  // });

  return (
    <div className="App">
      <AppRouter />
      <FeedbackForm />
    </div>
  );
}

export default App;
