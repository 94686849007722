import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EdiText from "react-editext";
import { styled } from '@mui/material/styles';
import { getLoggedInUserInfo, updateUserInfo } from '../../../../globals/firebaseConfigs';
import { VALID_EMAIL_DOMAINS } from '../../../../globals/constants';

const StyledEdiText = styled(EdiText)`
  button[editext="edit-button"] {
    border: 0;
    background-color: rgba(0, 0, 0, 0);
  }
  button[editext="save-button"] {
    width: 50px;
    &:hover {
      background: greenyellow;
    }
  }
  button[editext="cancel-button"] {
    &:hover {
      background: crimson;
      color: #fff;
    }
  }
  input, textarea {
    background: #1D2225;
    color: #F4C361;
    font-weight: bold;
    border-radius: 5px;
  }
  button[editext="edit-button"][class="disabledStyledEdiText"] {
    display: none;
  }
  `

const MyprofileBasicdetails = () => {

  const [username, setUsername] = useState('');
  const [university, setUniversity] = useState('');
  const [branch, setBranch] = useState('');
  const [batch, setBatch] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [phone, setPhone] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [facebook, setFacebook] = useState('');
  const [email, setEmail] = useState('');
  const [instagram, setInstagram] = useState('');
  const [waiting, setWaiting] = useState(false);

  const handleSave = (type, val) => {
    let info = {};
    info[type] = val;
    updateUserInfo(sessionStorage.getItem("uid"), info);
    sessionStorage.setItem(type, val);
  }

  useEffect(() => {
    setWaiting(true);
    setBranch(sessionStorage.getItem("branch"));
    setBatch(sessionStorage.getItem("batch"));
    setWhatsapp(sessionStorage.getItem("whatsapp"));
    setPhone(sessionStorage.getItem("phone"));
    setLinkedin(sessionStorage.getItem("linkedin"));
    setFacebook(sessionStorage.getItem("facebook"));
    setEmail(sessionStorage.getItem("email"));
    setInstagram(sessionStorage.getItem("instagram"));
    setUsername(sessionStorage.getItem("name"));
    setUniversity(sessionStorage.getItem("university"));
    setWaiting(false);
  });

  return (
    <div id='basicdetails'>
      <Typography sx={{ color: "#0561B8FF", mt: 1 }} variant="h6">
        Basic Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="p">
            Name:
          </Typography>
          <StyledEdiText
            type="text"
            value={username}
            onSave={(val) => {handleSave('name', val);}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="p">
            University:
          </Typography>
          <StyledEdiText
            editButtonClassName='disabledStyledEdiText'
            type="text"
            value={university}
            // onSave={handleSaveUniversity} as we can't change this
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="p">
            Branch:
          </Typography>
          <StyledEdiText
            type="text"
            value={branch}
            onSave={(val) => {handleSave('branch', val);}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="p">
            Batch:
          </Typography>
          <StyledEdiText
            type="text"
            value={batch}
            onSave={(val) => {handleSave('batch', val);}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="p">
            Whatsapp:
          </Typography>
          <StyledEdiText
            type="text"
            value={whatsapp}
            onSave={(val) => {handleSave('whatsapp', val);}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="p">
            Phone:
          </Typography>
          <StyledEdiText
            type="text"
            value={phone}
            onSave={(val) => {handleSave('phone', val);}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="p">
            Linkedin:
          </Typography>
          <StyledEdiText
            type="text"
            value={linkedin}
            onSave={(val) => {handleSave('linkedin', val);}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="p">
            Facebook:
          </Typography>
          <StyledEdiText
            type="text"
            value={facebook}
            onSave={(val) => {handleSave('facebook', val);}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="p">
            Email:
          </Typography>
          <StyledEdiText
            editButtonClassName='disabledStyledEdiText'
            type="text"
            value={email}
            // onSave={handleSaveEmail} as we never change this value
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="p">
            Instagram:
          </Typography>
          <StyledEdiText
            type="text"
            value={instagram}
            onSave={(val) => {handleSave('instagram', val);}}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default MyprofileBasicdetails;
