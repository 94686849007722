import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import RequireAuth from './RequireAuth';
import AboutUsPage from '../pages/about-us-page/about-us-page';
import NewsletterSubscription from '../pages/newsletter-subscription/newsletter-subscription';
import LeaseListings from '../pages/lease-listings/lease-listings';
import Navbar from '../components/navbar/navbar';
import PageNotFound from '../pages/page-not-found/page-not-found';
import Loginpage from '../pages/loginpage/loginpage';
import SignupForm from '../components/signup-form/signup-form';
import AddListing from '../components/add-listing/add-listing';
import ShowListing from '../components/show-listing/show-listing';
import CourseExchange from '../components/course-exchange/course-exchange';
import FamilyPlanner from '../components/family-planner/family-planner';
import QnaBoard from '../pages/qna-board-page/qna-board-page';
import CouponsPage from '../pages/coupons-page/coupons-page';
import MyprofilePage from '../pages/myprofile-page/myprofile-page';
// import Test from '../pages/test/test';

const AppRouter = () => {

  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          {/* All the routes must direct to a /page/ component, and not some component under components */}
          <Route path="/subscribe" element={<NewsletterSubscription />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/login" element={<Loginpage />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/family" element={<FamilyPlanner />} />
          <Route path="/qna" element={<QnaBoard />} />
          <Route path="/coupons" element={<CouponsPage />} />
          {/* <Route path="/test" element={<Test />} /> */}

          <Route path="/" element={<RequireAuth><LeaseListings /></RequireAuth>} />
          <Route path="/leaselistings" element={<RequireAuth><LeaseListings /></RequireAuth>} />
          <Route path="/leaselistings/:listingid" element={<RequireAuth><ShowListing /></RequireAuth>} />
          <Route path="/addlisting" element={<RequireAuth><AddListing /></RequireAuth>} />
          <Route path="/coursexchange" element={<RequireAuth><CourseExchange /></RequireAuth>} />
          <Route path="/myprofile" element={<RequireAuth><MyprofilePage /></RequireAuth>} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default AppRouter;
