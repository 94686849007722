import React from "react";
import DocumentMeta from 'react-document-meta';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {
  CssBaseline,
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  Paper,
  Stack,
  Container,
  createTheme,
  ThemeProvider,
  Breadcrumbs,
  Link
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LottieUnderConstruction from '../lottie/under-construction/lottie/under-construction';


const theme = createTheme();
const meta = {
  title: 'HousingNet | Family Plans',
  description: 'Look for and join a mobile plan with your friends!',
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags,housing,mobile,plans,family'
    }
  }
};

const steps = [
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  ''
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const FamilyPlanner = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [showunderconstruction, toggleUnderConstruction] = React.useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <DocumentMeta {...meta}>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Typography sx={{color: "#0561B8FF"}} variant="h4">
                Family Planner
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button variant="contained" color="primary" size="large" fullWidth>
                Create Family
              </Button>
            </Grid>
            <Grid item xs={12}>
              {/* TODO: Run this Accordion into loop for as many active family plans available */}
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                      >
                        <Link underline="hover" key="111" color="inherit">Phone</Link>,
                        <Link underline="hover" key="222" color="inherit">T-Mobile</Link>,
                        <Typography key="333" color="text.primary">Magenta Max</Typography>
                      </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography sx={{ color: 'text.secondary' }}>5 Pending Requests</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Button
                        fullWidth
                        variant="contained"
                      >
                        Request to join
                      </Button>
                    </Grid>
                    {/* Removing stepper as it is not adding any value for now */}
                    {/* <Grid item xs={12}>
                        <Stepper activeStep={1} alternativeLabel>
                          {steps.map((label, i) => (
                            <Step key={i}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                    </Grid> */}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ width: '100%' }}>
                    <Stack spacing={2}>
                      {/* TODO: Run this Item into loop for as many active requests available */}
                      <Item>
                        <Grid container spacing={2}>
                          <Grid item md={8}>
                            <div style={{
                              textAlign: "left"
                            }}>
                              <Button>xyz@buffalo.edu</Button>
                            </div>
                          </Grid>
                          <Grid item md={4}>
                            <div style={{
                              textAlign: "right"
                            }}>
                              <Button variant="contained" size="large" color="success">
                                Accept
                              </Button>{' '}
                              <Button variant="outlined" size="large" color="error">
                                Reject
                              </Button>

                            </div>
                          </Grid>
                        </Grid>
                      </Item>
                    </Stack>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Dialog onClick={() => toggleUnderConstruction(false)}
        open={showunderconstruction}
        // keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <LottieUnderConstruction />
        <Typography align="center" variant="h6" m={2}>This page is still under construction and things might not work as expected. Click anywhere to move ahead.</Typography>
      </Dialog>
    </ThemeProvider>
    </DocumentMeta>
  );
}

export default FamilyPlanner;
