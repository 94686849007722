import { Typography } from '@mui/material';
import React from 'react';

const MyprofileProductlistings = () => (
  <div id='myproducts'>
    <Typography sx={{color: "#0561B8FF", mt: 1}} variant="h6">
      My Products
    </Typography>
    <Typography variant="p">
      No products on sale from you. (This section is work in progress)
    </Typography>
  </div>
);

export default MyprofileProductlistings;
