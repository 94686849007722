import React from 'react';
import styles from './buy-sell.module.css';

import Lottie from "lottie-react";
import exchangeAnimation from "./114216-product-delivered.json";

const LottieBuySell = () => (
  <div className={styles.LottieBuySell} data-testid="LottieBuySell">
    <Lottie animationData={exchangeAnimation} loop={true} />
  </div>
);

LottieBuySell.propTypes = {};

LottieBuySell.defaultProps = {};

export default LottieBuySell;
