import React from 'react';
import { Button, Grid, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import img_visily from '../../assets/images/visily.jpg';
import img_oracle from '../../assets/images/oracle.jpg';
import img_python from '../../assets/images/python.jpg';
import img_awslambda from '../../assets/images/awslambda.jpg';
import img_reactjs from '../../assets/images/reactjs.jpg';
import img_firebase from '../../assets/images/firebase.jpg';
import img_confluence from '../../assets/images/confluence.jpg';

const theme = createTheme();

const stack_image_style = {
  height: 'auto',
  maxHeight: '100px',
  width: 'auto',
  maxWidth: '100px',
};

const Techstack = () => (
  <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" component="div">
          Technology Stack
        </Typography>
        <Typography variant="h7" component="div">
          We have a cloud first development and deployment model, using all the modern tools and some AI dressings. 
        </Typography>

        <Grid container spacing={1}>
          <Grid item container xs={4} md={2} direction="row" alignItems="flex-end" justify="center">
            <Card>
              <CardContent>
                <img
                  src={img_visily}
                  alt="Visily"
                  style={stack_image_style}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item container xs={4} md={2} direction="row" alignItems="flex-end" justify="center">
            <Card>
              <CardContent>
                <img
                  src={img_python}
                  alt="Python"
                  style={stack_image_style}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item container xs={4} md={2} direction="row" alignItems="flex-end" justify="center">
            <Card>
              <CardContent>
                <img
                  src={img_firebase}
                  alt="Firebase"
                  style={stack_image_style}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item container xs={4} md={2} direction="row" alignItems="flex-end" justify="center">
            <Card>
              <CardContent>
                <img
                  src={img_reactjs}
                  alt="ReactJS"
                  style={stack_image_style}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item container xs={4} md={2} direction="row" alignItems="flex-end" justify="center">
            <Card>
              <CardContent>
                <img
                  src={img_awslambda}
                  alt="AWS Lambda"
                  style={stack_image_style}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item container xs={4} md={2} direction="row" alignItems="flex-end" justify="center">
            <Card>
              <CardContent>
                <img
                  src={img_oracle}
                  alt="Oracle"
                  style={stack_image_style}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item container xs={4} md={5} direction="row" alignItems="flex-end" justify="center">

          </Grid>
          <Grid item container xs={4} md={2} direction="row" alignItems="flex-end" justify="center">
            <Card>
              <CardContent>
                <img
                  src={img_confluence}
                  alt="Confluence"
                  style={stack_image_style}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item container xs={4} md={5} direction="row" alignItems="flex-end" justify="center">

          </Grid>
        </Grid>

        <Link href="mailto:mandeepv@buffalo.edu?subject=%5Bhousingnet.org%5D%20Interest%20to%20join&body=Hi%2C%0D%0AI%20wanted%20to%20join%20your%20team.%0D%0A%0D%0ARegards" target="_blank" rel="noopener noreferrer">
          <Button variant="contained" sx={{ margin: 5 }}>Join Us!</Button>
        </Link>
      </Box>
    </Container>
  </ThemeProvider>
);

export default Techstack;
