import React from 'react';
import DocumentMeta from 'react-document-meta';
import { Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import CouponCard from '../../components/coupon-card/coupon-card';
import LottieUnderConstruction from '../../components/lottie/under-construction/lottie/under-construction';

const theme = createTheme();
const meta = {
  title: 'HousingNet | Coupons',
  description: 'Redeem your beecoins for exciting offers!',
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags,housing,coupons,freestuff,marketing'
    }
  }
};

const CouponsPage = () => {
  const [showunderconstruction, toggleUnderConstruction] = React.useState(true);

  return (
    <DocumentMeta {...meta}>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{ mt: 20}}
        >
          <Typography sx={{color: "#0561B8FF"}} variant="h4">
            Redeem Beecoins
          </Typography>

          <Grid container spacing={2} sx={{mt: 2}}>
            <Grid item xs={12} md={4}>
              <CouponCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <CouponCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <CouponCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <CouponCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <CouponCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <CouponCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <CouponCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <CouponCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <CouponCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <CouponCard />
            </Grid>
            <Grid item xs={12} md={4}>
              <CouponCard />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Dialog onClick={() => toggleUnderConstruction(false)}
        open={showunderconstruction}
        // keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <LottieUnderConstruction />
        <Typography align="center" variant="h6" m={2}>This page is still under construction and things might not work as expected. Click anywhere to move ahead.</Typography>
      </Dialog>
    </ThemeProvider>
    </DocumentMeta>
  );
}

export default CouponsPage;
