import React from 'react';
import styles from './homemade-food.module.css';

import Lottie from "lottie-react";
import homemadeAnimation from "./75783-prepare-food.json";

const LottieHomemadeFood = () => (
  <div className={styles.LottieHomemadeFood} data-testid="LottieHomemadeFood">
    <Lottie animationData={homemadeAnimation} loop={true} />
  </div>
);

LottieHomemadeFood.propTypes = {};

LottieHomemadeFood.defaultProps = {};

export default LottieHomemadeFood;
