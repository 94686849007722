import * as React from 'react';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Snackbar,
  Grid,
  Tooltip
} from '@mui/material';
import { red } from '@mui/material/colors';
import MuiAlert from '@mui/material/Alert';
import Carousel from 'react-material-ui-carousel';
import Chip from '@mui/material/Chip';
import LunchDiningRoundedIcon from '@mui/icons-material/LunchDiningRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import ManRoundedIcon from '@mui/icons-material/ManRounded';
import WomanRoundedIcon from '@mui/icons-material/WomanRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { BASEURL } from '../../globals/constants';
import ContactOptionsButton from '../contact-options-button/contact-options-button';
import { getLeaseAvailability, saveLeaseAvailability } from '../../globals/firebaseConfigs';
import dayjs from 'dayjs';

export default function LeaseListingCard({listingdata, fv}) {
  // fv = fullview, show full view of the card without hiding any detail

  const key = listingdata[0];
  const data = listingdata[1];

  const [showcopysuccesssnackbar, setShowCopySuccessSnackbar] = React.useState(false);
  const [avl, setAvl] = React.useState(0);
  const [unavl, setUnavl] = React.useState(0);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowCopySuccessSnackbar(false);
  };

  const updateLeaseAvailability = (available) => {
    const userinfo = JSON.parse(sessionStorage.getItem("user-info")); // TODO: use redux for this everywhere
    saveLeaseAvailability(key, userinfo.uid, {time: dayjs().toString(), res: available});
    if(available == "YES")
      setAvl(avl+1);
    else
      setUnavl(unavl+1);
  }

  React.useEffect(() => {
    getLeaseAvailability(key).then((snap) => {
      var tempavl = 0, tempnavl = 0;
      // iterate each response
      Object.values(snap.val() || {}).forEach((el) => {
        // check if date is within last 1 month
        if (dayjs(el.time)>dayjs().subtract(1, 'month')){
          if(el.res == "YES")
            tempavl += 1;
          else
            tempnavl += 1;
        }
      });
      setAvl(tempavl);
      setUnavl(tempnavl);
    });
  });

  return (
    <>
      <Card sx={{ maxWidth: '90%', mt: 5 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {data.street}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={data.totalroomsavailable + ' ' + data.privacy + ' room in ' + data.totalrooms + 'BH' + data.totalbathrooms + 'K at ' + data.housenumber + ' ' + data.street}
          subheader={data.isavailable ? 'From: ' + data.subleasestart + ' to: ' + data.subleaseend : 'Available after: ' + data.subleaseend}
        />
        {
          data.imagefiles && data.imagefiles.length>0 && (
            <Carousel 
              indicators
              next={ () => {} }
              prev={ () => {} }
            >
              {
                  data.imagefiles.map( (item, i) => <CardMedia
                    key={i}
                    component="img"
                    height="194"
                    image={item || ''}
                    alt={data.housenumber + ' ' + data.street}
                  /> )
              }
            </Carousel>
          )
        }
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Chip icon={<LunchDiningRoundedIcon />} label={data.foodpref} variant="outlined" color="primary"/>
              <Chip icon={data.gender == 'Female' ? <WomanRoundedIcon /> : <ManRoundedIcon />} label={data.gender} variant="outlined" color="primary"/>
              <Chip icon={<LockRoundedIcon />} label={data.privacy} variant="outlined" color="primary"/>
              <Chip icon={<MonetizationOnIcon />} label={data.monthlyrent + "/month"} variant="outlined" color="success"/>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title="Based on last 1 month inputs">
                <Typography variant="p" color={avl<unavl?"error":"success.main"}>
                  <ReportProblemIcon fontSize="small"/>
                  {avl} say available, {unavl} say unavailable.
                </Typography>
              </Tooltip>
            </Grid>
            {fv && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    {data.comment}
                  </Typography>
                </Grid>
                <Grid item xs={6} container spacing={1}>
                  <fieldset>
                    <legend>Included in House</legend>
                      {(data.roominclusions || []).map((item, idx) => <Chip key={idx} label={item} />)}
                  </fieldset>
                </Grid>
                <Grid item xs={6} container spacing={1}>
                  <fieldset>
                    <legend>Included in Kitchen</legend>
                      {(data.kitcheninclusions || []).map((item, idx) => <Chip key={idx} label={item} />)}
                  </fieldset>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
        {fv && (<CardActions disableSpacing>
          <Tooltip title="Adding to Favourites is Coming Soon">
            <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton>
          </Tooltip>
          <IconButton aria-label="share" onClick={() => {navigator.clipboard.writeText(BASEURL + '/leaselistings/' + key).then(()=>setShowCopySuccessSnackbar(true))}}>
            <ShareIcon />
          </IconButton>

          <ContactOptionsButton contactdetails={data.landlord} contactpersontype={'Landlord'}/>
          <ContactOptionsButton contactdetails={data.addercontactdetails} contactpersontype={'Student'} checkLeaseAvailability={true} updateLeaseAvailability={updateLeaseAvailability}/>
        </CardActions>)
        }
      </Card>
      <Snackbar open={showcopysuccesssnackbar} autoHideDuration={4000} onClose={handleClose}>
        <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }} elevation={6} variant="filled">
          Link copied to clipboard!
        </MuiAlert>
      </Snackbar>
    </>
  );
}