import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import mapboxgl from 'mapbox-gl';
import {MAPBOX_TOKEN} from '../../globals/constants';
import { addTracker } from '../../globals/firebaseConfigs';
import 'mapbox-gl/dist/mapbox-gl.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
// import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';
import LeaseListingCard from '../../components/lease-listing-card/lease-listing-card';
import Carousel from 'react-material-ui-carousel'
import HouseIcon from '@mui/icons-material/House';
import { Button } from '@mui/material';

mapboxgl.accessToken = MAPBOX_TOKEN;

const theme = createTheme();

const Marker = ({ onClick, children, idx }) => {
  const _onClick = (idx) => {
    onClick(idx);
  };

  return (
    <Button onClick={() => _onClick(idx)} className="marker">
      {children}
    </Button>
  );
};

const LeaseListingsMapview = ({listings, filterData}) => {
  const mapContainerRef = useRef(null);
  const [caridx, setIdx] = React.useState(1);

  const [lng, setLng] = useState(-78.8312812);
  const [lat, setLat] = useState(42.9564003);
  const [zoom, setZoom] = useState(15);

  // Initialize map when component mounts
  useEffect(() => {

    addTracker('leaselistingmappage');

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom
    });

    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });

    // Render custom marker components
    Object.entries(listings).map((val, idx) => {

      const key = val[0];
      const listingdata = val[1];

      if (filterData(listingdata)) {
        // Create a React ref
        const ref = React.createRef();
        // Create a new DOM node and save it to the React ref
        ref.current = document.createElement("div");
        // Render a Marker Component on our new DOM node
        const root = ReactDOM.createRoot(ref.current);
        root.render(
          // <Button>Something</Button>
          <Marker onClick={()=>markerClicked(idx)} listing={listingdata} >
            <HouseIcon fontSize="large" />
          </Marker>
        );

        // Create a Mapbox Marker at our new DOM node
        // console.log([listingdata.lng, listingdata.lat]);
        new mapboxgl.Marker(ref.current)
          .setLngLat([listingdata.lng, listingdata.lat])
          .addTo(map);
        }
      })

    // listings.forEach((listing) => {
    //   // Create a React ref
    //   const ref = React.createRef();
    //   // Create a new DOM node and save it to the React ref
    //   ref.current = document.createElement("div");
    //   // Render a Marker Component on our new DOM node
    //   ReactDOM.render(
    //     <Marker onClick={markerClicked} listing={listing} />,
    //     ref.current
    //   );

    //   // Create a Mapbox Marker at our new DOM node
    //   new mapboxgl.Marker(ref.current)
    //     .setLngLat([listing.lat, listing.lng])
    //     .addTo(map);
    // });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    // Clean up on unmount
    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const markerClicked = (idx) => {
    // window.alert(idx);
    setIdx(idx);
  };

  return (
    <>
      <div className='map-container' ref={mapContainerRef} style={{width: window.innerWidth*0.95, height: window.innerHeight*0.7}}/>
      <Carousel
        sx={{width: 1}}
        index={caridx}
        indicators
        next={ () => {} }
        prev={ () => {} }
        onChange={(n)=>setIdx(n)}
      >
        {
          Object.keys(listings).length > 0 ? (Object.entries(listings).map((val, key) => 
            filterData(val) && (<LeaseListingCard listingdata={val} key={key}/>)
          )) : "No Listings with these filters"
          }
      </Carousel>
    </>
  );
};

export default LeaseListingsMapview;