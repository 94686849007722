import React, {useEffect} from 'react';
import LeaseListingCard from '../../components/lease-listing-card/lease-listing-card';
import { Box } from '@mui/material';
import { addTracker } from '../../globals/firebaseConfigs';

const LeaseListingsListview = ({listings, filterData}) => {

  useEffect(() => addTracker('leaselistinglistpage'));

  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {
      Object.keys(listings).length > 0 ? (Object.entries(listings).map((val, key) => 
        filterData(val) && (<LeaseListingCard listingdata={val} key={key} fv={true}/>)
      )) : "No Listings with these filters"
      }
    </Box>
  );
};

export default LeaseListingsListview;
