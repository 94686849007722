import React, { useState, useEffect } from 'react';
import DocumentMeta from 'react-document-meta';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import LottieVito from '../../components/lottie/vito/lottie/vito';
import LottieSuccess from '../../components/lottie/success/lottie/success';
import emailjs from '@emailjs/browser';

import { registerOTP, getRegisteredOTP, createUserAccount, addEmailToMailingList, addTracker } from '../../globals/firebaseConfigs';
import { EMAILJS_PUBLICKEY, EMAILJS_SERVICEID, EMAILJS_TEMPLATEID_OTPVERIFICATIONMAIL } from '../../globals/constants';
import { validateEmail, extractLatestOTP } from '../../globals/utils'

const theme = createTheme();
const meta = {
  title: 'HousingNet | Welcome',
  description: 'Subscribe to our newsletter to keep updated!',
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags,housing,newsletter,subscription'
    }
  }
};

export default function NewsletterSubscription() {

  // state to toggle password input box
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const togglePasswordInput = () => { setShowPasswordInput(!showPasswordInput) }

  // state to toggle OTP input box
  const [showOTPInput, setShowOTPInput] = useState(false);

  // state to toggle dialogues
  const [dialogueText, setDialogueText] = useState('');

  // state to maintain button text
  const [statusText, setStatusText] = useState('');
  const [buttonText, setButtonText] = useState('Send Verification OTP');

  useEffect(() => addTracker('subscriptionpage'));

  const handleSubmit = (event) => {
    event.preventDefault();

    // get email to verify
    const data = new FormData(event.currentTarget);
    const email = data.get('email').trim() || '';

    // check if we need to send otp; indicator: otp components are not visible
    if (!showOTPInput){
      // TODO: check if user already subscribed, or OTP was sent in last 10 minutes

      // check if email is a valid domain
      if (validateEmail(email)){
        // create a random OTP
        let otp = Math.floor(100000 + Math.random() * 900000)
        // create expiry_time for 10 mins
        let expiry_time = new Date(new Date().getTime() + 10*60000).toString();

        // register OTP to firebase database
        setStatusText('Registering OTP to firebase RDB...');
        registerOTP(email, otp, expiry_time).then(() => {
          setStatusText('Registeration successful...');

          // when successfully registered send OTP
          setStatusText('Sending OTP to ' + email + ' ...');
          var templateParams = {
            otp: otp,
            expiry_time: expiry_time,
            to_email: email
          };

          emailjs.send(EMAILJS_SERVICEID, EMAILJS_TEMPLATEID_OTPVERIFICATIONMAIL, templateParams, EMAILJS_PUBLICKEY)
            .then((result) => {
                // console.log(result.text);
                // if OTP sent successfully show OTP input box and update status text
                setShowOTPInput(true);
                setButtonText('Subscribe');
                setStatusText('OPT successfully sent to ' + email + ', valid till ' + expiry_time);
            }, (emailJSerror) => {
                console.log(emailJSerror.text);
                // if failed to sent OTP, update the status text
                setStatusText('Failed to send OTP email with error: ' + emailJSerror.text);
            });
        }, (firebaseError) => {
          console.log(firebaseError.text);
          // if failed to send OTP, update the status text
          setStatusText('Failed to register OTP with error: ' + firebaseError.text);
        });
      } else {
        setStatusText('We allow only valid @yourschool.edu emails.');
      }
    } 
    // this case executes when user is verifying the OTP
    else {
      // get the entered OTP
      const submittedOTP = data.get('otp').trim();

      // get the OTP from firebase for this email
      getRegisteredOTP(email).then((snap) => {
        // if the OTP is same as the submitted OTP
        if (extractLatestOTP(snap.val()) == submittedOTP){
          // add user to mailing list
          addEmailToMailingList(email).then(() => {

            // this case executes when user wants to create an account too
            if (showPasswordInput) {
              // signup user with email and password
              const submittedPassword = data.get('password');
              createUserAccount(email, submittedPassword).then(() => {
                setStatusText('Successfully subscribed to newsletters and also created an account.');
                setDialogueText(statusText);
              });
            } else {
              setStatusText('Successfully subscribed to newsletters.');
              setDialogueText(statusText);
            }
          });
        } else {
          setStatusText('OTP not correct, please check and try again.')
        }
      });
    }
  }

  return (
    <DocumentMeta {...meta}>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <LottieVito />
          <Typography component="h1" variant="h4">
            Welcome to HousingNet!
          </Typography>
          <Typography component="h1" variant="h6">
            <Link href="/aboutus">About Us</Link>
          </Typography>
          <Typography component="h1" variant="h6">
            We are constructing this day in and day out!
          </Typography>
          <Typography component="h1" variant="h6">
            Please subscribe for updates!
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="University Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Typography component="p">
              {statusText}
            </Typography>
            {
              showOTPInput &&
              <>
                <TextField
                  margin="normal"
                  fullWidth
                  name="otp"
                  label="OTP *"
                  type="text"
                  id="otp"
                />
                <FormControlLabel
                  control={<Checkbox value="createaccount" color="primary" onClick={togglePasswordInput}/>}
                  label="Also create my account"
                />
              </>
            }
            {
              showPasswordInput &&
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password *"
                type="password"
                id="password"
                inputProps={{ minLength: 8,  }}
                autoComplete="current-password"
              />
            }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              { buttonText }
            </Button>
          </Box>
        </Box>
        
      </Container>
      
    </ThemeProvider>
    <Dialog
      open={dialogueText!==''}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <LottieSuccess />
      <Typography component="p" align='center'>
        {statusText}
      </Typography>
    </Dialog>
    </DocumentMeta>
  );
}