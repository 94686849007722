import React, { useState } from 'react';
import {
  IconButton,
  DialogActions
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';

const ContactCard = ({contactdetails}) => (
  <DialogActions>
    {contactdetails.ph ? (
      <a href={"tel:" + contactdetails.ph} target="_blank" rel="noopener noreferrer">
        <IconButton color="primary" aria-label="Phone">
          <CallIcon />
        </IconButton>
      </a>) : (
      <IconButton color="primary" aria-label="Phone" disabled>
        <CallIcon />
      </IconButton>)}

    {contactdetails.em ? (
      <a href={"mailto:" + contactdetails.em + "?subject=%5Bhousingnet.org%5D%20query%20about%20your%20listing&body=Hi%2C%0D%0AI%20wanted%20to%20ask%20about%20your%20listing.%0D%0A%0D%0ARegards"} target="_blank" rel="noopener noreferrer">
        <IconButton color="primary" aria-label="Email">
          <EmailIcon />
        </IconButton>
      </a>) : (
      <IconButton color="primary" aria-label="Email" disabled>
        <EmailIcon />
      </IconButton>)}

    {contactdetails.fb ? (
      <a href={"https://m.me/" + contactdetails.fb} target="_blank" rel="noopener noreferrer">
        <IconButton color="primary" aria-label="Facebook Profile">
          <FacebookIcon />
        </IconButton>
      </a>) : (
      <IconButton color="primary" aria-label="Facebook Profile" disabled>
        <FacebookIcon />
      </IconButton>)}

    {contactdetails.wa ? (
      <a href={"https://wa.me/" + contactdetails.wa} target="_blank" rel="noopener noreferrer">
        <IconButton color="primary" aria-label="WhatsApp">
          <WhatsAppIcon />
        </IconButton>
      </a>) : (
      <IconButton color="primary" aria-label="WhatsApp" disabled>
        <WhatsAppIcon />
      </IconButton>)}
  </DialogActions>
);

ContactCard.propTypes = {};

ContactCard.defaultProps = {};

export default ContactCard;
