import React from 'react';
import styles from './vito.module.css';

import Lottie from "lottie-react";
import vitoAnimation from "./124429-vito-englishvit.json";

const LottieVito = () => (
  <div className={styles.LottieVito} data-testid="LottieVito">
    <Lottie animationData={vitoAnimation} loop={true} />
  </div>
);

LottieVito.propTypes = {};

LottieVito.defaultProps = {};

export default LottieVito;
