import React, { useEffect } from 'react';
import { addTracker } from '../../globals/firebaseConfigs';
import { Button, Grid, Link } from '@mui/material';
import styles from './about-us.module.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';

import LottieCommunity from '../lottie/community/lottie/community';
import LottieSublease from '../lottie/sublease/lottie/sublease';
import LottieSearchHome from '../lottie/search-home/lottie/search-home';
import LottiePayRent from '../lottie/pay-rent/lottie/pay-rent';
import LottieCarPool from '../lottie/car-pool/lottie/car-pool';
import LottieHomemadeFood from '../lottie/homemade-food/lottie/homemade-food';
import LottieBuySell from '../lottie/buy-sell/lottie/buy-sell';
import LottieRoommates from '../lottie/roommates/lottie/roommates';

const theme = createTheme();

const AboutUs = () => {

  useEffect(() => addTracker('aboutuspage'));

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" component="div">
            About Us
          </Typography>
          <Typography variant="h7" component="div">
            We are trying to build a closed community of students and a crowdsourced data of all student housing available around UB. We attempt to make it easy for upcoming students to settle down quick. The raw data added by community is persistent and free of charge for all generations to come.
          </Typography>

          <Link href="/subscribe"><Button variant="contained" sx={{ margin: 5 }}>Subscribe to updates!</Button></Link>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CardContent sx={{ boxShadow: 2 }}>
                <LottieSearchHome className={styles.Lottie} style={{ height: 400 }} />
                <Typography variant="h5" component="div">
                  Informed decision on study homes
                </Typography>
              </CardContent>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CardContent sx={{ boxShadow: 2 }}>
                <LottieSublease className={styles.Lottie} style={{ height: 400 }} />
                <Typography variant="h5" component="div">
                  Sublease your room effectively
                </Typography>
              </CardContent>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardContent sx={{ boxShadow: 2 }}>
                <LottieRoommates className={styles.Lottie} style={{ height: 200 }} />
                <Typography variant="h5" component="div">
                  Find roommates with matching interests
                </Typography>
              </CardContent>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CardContent sx={{ boxShadow: 2 }}>
                <LottieCarPool className={styles.Lottie} style={{ height: 400 }} />
                <Typography variant="h5" component="div">
                  Find people and make memories over long rides
                </Typography>
              </CardContent>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CardContent sx={{ boxShadow: 2 }}>
                <LottieBuySell className={styles.Lottie} style={{ height: 400 }} />
                <Typography variant="h5" component="div">
                  Get stuff you need from nearby
                </Typography>
              </CardContent>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CardContent sx={{ boxShadow: 2 }}>
                <LottieHomemadeFood className={styles.Lottie} style={{ height: 400 }} />
                <Typography variant="h5" component="div">
                  Don't miss homemade food away from home
                </Typography>
              </CardContent>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CardContent sx={{ boxShadow: 2 }}>
                <LottiePayRent className={styles.Lottie} style={{ height: 400 }} />
                <Typography variant="h5" component="div">
                  Pay rent and deposits securely
                </Typography>
              </CardContent>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardContent sx={{ boxShadow: 2 }}>
                <LottieCommunity className={styles.Lottie} style={{ height: 400 }} />
                <Typography variant="h5" component="div">
                  Be part of a great community
                </Typography>
              </CardContent>
            </Grid>
          </Grid>

          <Link href="/subscribe"><Button variant="contained" sx={{ margin: 5 }}>Subscribe to updates!</Button></Link>

        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default AboutUs;
