import { Typography } from '@mui/material';
import React from 'react';

const MyprofileFamilyplans = () => (
  <div id='myfamilyplan'>
    <Typography sx={{color: "#0561B8FF", mt: 1}} variant="h6">
      Family plans
    </Typography>
    <Typography variant="p">
      You are not in any of the family plans, join one to reap benefits! (This section is work in progress)
    </Typography>
  </div>
);

export default MyprofileFamilyplans;
