import React, { useEffect, useState } from 'react';
import DocumentMeta from 'react-document-meta';
import {
  CssBaseline,
  Box,
  Container,
  createTheme,
  ThemeProvider,
  Button,
  Grid,
  Dialog,
  Select,
  Switch,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Tooltip
  // TextField
} from '@mui/material';

// import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';

import LeaseListingsListview from '../../components/lease-listings-listview/lease-listings-listview';
import LeaseListingsMapview from '../../components/lease-listings-mapview/lease-listings-mapview';
import { getAllSubleases, getPropertyOptions } from '../../globals/firebaseConfigs';
import LottieLoading from '../../components/lottie/loading/lottie/loading';
// import { set } from 'firebase/database';

const theme = createTheme();
const meta = {
  title: 'HousingNet | Listings',
  description: 'Checkout the list of available leases!',
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags,housing,leasing'
    }
  }
};

const LeaseListings = () => {

  const [listings, setListings] = useState({});
  const [waiting, setWaiting] = useState(false);
  const [filterstreet, setFilterStreet] = useState('defaultall');
  const [filtergender, setFilterGender] = useState('defaultall');
  // const [filteravailablefromdate, setFilterAvailableFromDate] = useState(dayjs());
  // const [isdatedefault, setIsDateDefault] = useState(true);
  const [mapviewenabled, toggleMapView] = useState(false);
  // const [showsubscribesuccesssnackbar, setShowSubscribeSuccessSnackbar] = useState(false);
  const [PROPERTYOPTIONS, setPropertyOptions] = useState({});

  useEffect( () => {
    setWaiting(true);

    Promise.all([getAllSubleases(), getPropertyOptions()])
    .then((results) => {
      setListings(results[0].val());
      setPropertyOptions(results[1].val());
    })
    .then(() => setWaiting(false));

  }, [setListings, setWaiting, setPropertyOptions]);

  const filterData = (val) => {
    if (
      (filterstreet !== 'defaultall' && val[1].street !== filterstreet) || 
      (filtergender !== 'defaultall' && val[1].gender !== filtergender)
      )
      return false
    return true
  }

  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }

  //   setShowSubscribeSuccessSnackbar(false);
  // };

  // const subscribeToLeaseAdditions = () => {
  //   const email = JSON.parse(sessionStorage.getItem("user-info")).email;
  //   // add entry to firebase
  //   addSubscriberToLeaseAlerts(email).then(() => {
  //     setShowSubscribeSuccessSnackbar(true);
  //   });
  // }

  return (
    <DocumentMeta {...meta}>
    <ThemeProvider theme={theme}>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 1
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                href="/addlisting"
                color='success'
              >
                Add New Sublease
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Tooltip title="Coming Soon!">
                <Button
                  fullWidth
                  variant="contained"
                  sx={{backgroundColor: "#616161"}}
                  // color='primary'
                  // onClick={subscribeToLeaseAdditions}
                  // disabled
                >
                  Get notified on new Subleases! (coming soon)
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel id="streetlabel">Street</InputLabel>
                <Select
                  labelId="streetlabel"
                  id="street"
                  value={filterstreet}
                  label="Street"
                  onChange={(event) => setFilterStreet(event.target.value)}
                >
                  <MenuItem value={'defaultall'}>Show all</MenuItem>
                  {
                    (PROPERTYOPTIONS.streets || []).map((item, idx) => {
                      return (<MenuItem key={idx} value={item}>{item}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel id="genderlabel">Available For Gender</InputLabel>
                <Select
                  labelId="genderlabel"
                  id="gender"
                  name="gender"
                  value={filtergender}
                  label="Available For Gender"
                  onChange={(event) => setFilterGender(event.target.value)}
                >
                  <MenuItem value={'defaultall'}>Show all</MenuItem>
                  {
                    (PROPERTYOPTIONS.genders || []).map((item, idx) => {
                      return (<MenuItem key={idx} value={item}>{item}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={6} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="End date"
                  inputFormat="MM/DD/YYYY"
                  value={filteravailablefromdate}
                  onChange={(newDate) => setFilterAvailableFromDate(newDate)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid> */}
            <Grid item xs={6} md={3}>
              <FormControlLabel 
                control={<Switch 
                  checked={mapviewenabled} 
                  onChange={() => toggleMapView(!mapviewenabled)} 
                  inputProps={{ 'aria-label': 'controlled' }}/>} 
                label="Map view" />
            </Grid>
            <Grid item xs={6} md={3}>
              <Button onClick={() => {
                setFilterStreet('defaultall');
                setFilterGender('defaultall');
                // setFilterAvailableFromDate(dayjs());
              }}>Reset filter</Button>
            </Grid>
            <Grid item xs={12}>
                
            </Grid>
          </Grid>
          { 
            mapviewenabled ? 
              <LeaseListingsMapview  listings={listings} filterData={filterData} /> : 
              <LeaseListingsListview listings={listings} filterData={filterData} /> 
          }
        </Box>
      </Container>
      <Dialog
        open={waiting}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <LottieLoading />
      </Dialog>
      {/* <Snackbar open={showsubscribesuccesssnackbar} autoHideDuration={4000} onClose={handleClose}>
        <MuiAlert onClose={handleClose} severity="success" sx={{ width: '100%' }} elevation={6} variant="filled">
          Successfully subscribed to new lease additions!
        </MuiAlert>
      </Snackbar> */}
    </ThemeProvider>
    </DocumentMeta>
  );
}

export default LeaseListings;