import React from "react";
import ArrowDown from "../../assets/icons/arrow_drop_down.svg";
import ArrowUp from "../../assets/icons/arrow_drop_up.svg";
import ThumbUp from "../../assets/icons/thumb_up.svg";
import ThumbDown from "../../assets/icons/thumb_down.svg";
import Image1 from "../../assets/images/carouselimg1.jpg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {
  CssBaseline,
  Grid,
  Box,
  Typography,
  Container,
  createTheme, 
  ThemeProvider,
  Button,
  Chip,
  Stack,
  CardMedia
} from '@mui/material';

import Carousel from 'react-material-ui-carousel';
// Where can I check UB academic calendar?
const QuestionCard = () => {

  const imagefiles = [
    'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg',
    'https://img.freepik.com/free-photo/wide-angle-shot-single-tree-growing-clouded-sky-during-sunset-surrounded-by-grass_181624-22807.jpg',
    'https://static.vecteezy.com/packs/media/vectors/term-bg-1-3d6355ab.jpg'
  ];
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item container md={1} sx={{ display: { xs: 'none', md: 'flex' }}}>
          <Grid item xs={12}>
            <Stack flexDirection='column' justifyContent='flex-start' alignItems='center'>
              <ThumbUpOffAltIcon fontSize="large"/>
              <Typography fontWeight='bold' color="#171A1FFF" fontSize="14px" >25</Typography>
              <ThumbDownOffAltIcon fontSize="large"/>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack flexDirection='column' justifyContent="flex-end" alignItems="center">
              <Typography variant="p" color="#171A1FFF" fontSize="12px">
                Asked:
              </Typography>
              <Typography variant="p" color="#171A1FFF" fontSize="12px">
                11-Jan-2023
              </Typography>
              <Typography variant="p" color="#171A1FFF" fontSize="12px">
                14:12
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12} md={11}>
          <Typography variant="h6" fontWeight='bold'>
            This is the sample question with images and a question description
            unlike the last one?
          </Typography>
          <Grid item container xs={12}>
            <Grid item xs={6} sx={{ display: { xs: 'flex', md: 'none' }}}>
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
                <ThumbUpOffAltIcon fontSize="large"/>
                <Typography fontWeight='bold' color="#171A1FFF" fontSize="14px" >25</Typography>
                <ThumbDownOffAltIcon fontSize="large"/>
              </Stack>
            </Grid>
            <Grid item xs={6} md={12}>
              <Stack direction="row" spacing={1} alignItems="center" sx={{justifyContent: {xs: "flex-end", md: "flex-start"}}}>
                <Chip label="General" size="small"/>
                <Chip label="University" size="small"/>
                <Chip label="Rank" size="small"/>
              </Stack>
            </Grid>
          </Grid>
          <Typography variant="h6" sx={{fontSize: "16px"}}>
            This is some text description more about the question.This is some text description more about the question.This is some text description more about the question.This is some text description
          </Typography>
          <Carousel 
              indicators
              next={ () => {} }
              prev={ () => {} }
            >
              {
                imagefiles.map( (item, i) => <CardMedia
                  key={i}
                  component="img"
                  height="194"
                  image={item || ''}
                  alt={""}
                /> )
              }
          </Carousel>
          <Grid item container xs={12}>
            <Grid item xs={6} sx={{ mt: 1, display: { xs: 'flex', md: 'none' }}}>
              Asked: 11-Jan-2023 14:12
            </Grid>
            <Grid item xs={6} md={12}>
              <Stack direction="row" spacing={1} sx={{justifyContent: 'flex-end'}}>
                <Button
                  type="submit"
                  // fullWidth
                  variant="contained"
                  // sx={{ mt: 3, mb: 2}}
                >
                  Add answer
                </Button>
              </Stack>
            </Grid>
          </Grid>
          
          <div
            style={{
              marginTop: "22px",
              display: "flex",
              backgroundColor: "#F8F9FAFF",
              boxShadow: "0px 1px 5px #171a1f, 0px 0px 2px #171a1f",
              border: "2px",
              padding: "0 6px 0 18px",
            }}
          >
            <div
              style={{
                paddingRight: "9px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img alt="arrowup" src={ArrowUp} style={{ cursor: "pointer" }} />
              <Typography sx={{ color: "#171A1FFF", fontSize: "14px" }}>25</Typography>
              {/* <ArrowDropDownIcon sx={{ fontSize: "45px" }}/> */}
              <img alt="arrowdown" src={ArrowDown} style={{ cursor: "pointer" }} />
            </div>
            <div
              style={{
                backgroundColor: "#B8F5CDFF",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckCircleIcon color="success"/>
            </div>
            <Typography variant="p" sx={{ fontSize: "14px", color: "#9095A1FF", m: 1 }}>
              This is a sample accepted answer. It has a green tick ribbon beside it. Also the number of upvotes are visible.
            </Typography>
          </div>
          
          <div
            style={{
              marginTop: "5px",
              display: "flex",
              backgroundColor: "#F8F9FAFF",
              boxShadow: "0px 1px 5px #171a1f, 0px 0px 2px #171a1f",
              border: "2px",
              padding: "0 6px 0 18px",
            }}
          >
            <div
              style={{
                paddingRight: "9px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              
              <img alt="arrowup" src={ArrowUp} style={{ cursor: "pointer" }} />
              <Typography sx={{ color: "#171A1FFF", fontSize: "14px" }}>25</Typography>
              <img alt="arrowdown" src={ArrowDown} style={{ cursor: "pointer" }} />
              
            </div>
            <Typography variant="p" sx={{ fontSize: "14px", color: "#9095A1FF", m: 1 }}>
              This is a sample accepted answer. It has a green tick ribbon beside it. Also the number of upvotes are visible.
            </Typography>
          </div>

        </Grid>
      </Grid>
    </Container>
  );
};

export default QuestionCard;
