import * as React from 'react';
import BugReportIcon from '@mui/icons-material/BugReport';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { 
  Box,
  Typography,
  TextField,
  DialogTitle,
  Fab,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Slide
 } from '@mui/material';

import { addFeedback } from '../../globals/firebaseConfigs';
import ContactCard from '../contact-card/contact-card';
import dayjs from 'dayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FeedbackForm() {
  const [open, setOpen] = React.useState(false);
  const [feedbackText, setFeedbackText] = React.useState('');
  const [contactDetail, setContactDetail] = React.useState('');
  const timeOfFeedback = dayjs().toString();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveFeedback = () => {
    addFeedback(feedbackText, contactDetail, timeOfFeedback).then(()=>{
      setOpen(false);
      setFeedbackText('')});
  }

  return (
    <>
      <Box sx={{
        position: 'fixed',
        bottom: 10,
        right: 10
      }}
      onClick={handleClickOpen}
      >
        <Fab variant="extended" size="small">
          <FeedbackIcon />/<BugReportIcon sx={{ mr: 1 }} />
          <Typography variant='p'>
            Report
          </Typography>
        </Fab>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Report Bug or Anonymous Feedback</DialogTitle>
        <DialogContent>
          <TextField
            id="filled-multiline-static-contact"
            label="Any contact detail (optional)"
            multiline
            fullWidth
            rows={2}
            variant="filled"
            onChange={(e) => setContactDetail(e.target.value)}
            value={contactDetail}
          />
          <TextField
            id="filled-multiline-static-feedback"
            label="Your Comment"
            multiline
            fullWidth
            rows={4}
            variant="filled"
            onChange={(e) => setFeedbackText(e.target.value)}
            value={feedbackText}
          />
        </DialogContent>
        <DialogActions>
          <ContactCard contactdetails={{ph: '+17372874570', em: 'mandeepv@buffalo.edu', wa: '+17372874570', fb: 'mandeep.vratesh'}}/>
          <div style={{flex: '1 0 0'}} />
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveFeedback}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}