import React from 'react';
import { Button, Grid, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Mandeep from "../../assets/images/mandeep.jpg";
import Avatar from '@mui/material/Avatar';

const theme = createTheme();

const Team = () => (
  <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" component="div">
          Team
        </Typography>
        <Typography variant="h7" component="div">
          Working day in and day out to make this dream come true.
        </Typography>

        <Link href="mailto:mandeepv@buffalo.edu?subject=%5Bhousingnet.org%5D%20Interest%20to%20join&body=Hi%2C%0D%0AI%20wanted%20to%20join%20your%20team.%0D%0A%0D%0ARegards" target="_blank" rel="noopener noreferrer">
          <Button variant="contained" sx={{ margin: 5 }}>Join Us!</Button>
        </Link>

        <Grid
          container
          direction="row"
          justify="center"
          spacing={2}
        >
          <Grid item md={4}></Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Card sx={{ maxWidth: 300 }}>
              <Avatar alt="Mandeep Vratesh" src={Mandeep} sx={{ width: 200, height: 200, margin: 1 }}/>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Mandeep Vratesh
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Founder, Designer, Architect, Developer
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="https://www.linkedin.com/in/mandeep-vratesh/"
                >
                  Linkedin
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="mailto:mandeepv@buffalo.edu"
                >
                  Email
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
      </Box>
    </Container>
  </ThemeProvider>
);

export default Team;
