import React from 'react';
import styles from './sublease.module.css';

import Lottie from "lottie-react";
import subleaseAnimation from "./121377-key-in-hand.json";

const LottieSublease = () => (
  <div className={styles.LottieSublease} data-testid="LottieSublease">
    <Lottie animationData={subleaseAnimation} loop={true} />
  </div>
);

LottieSublease.propTypes = {};

LottieSublease.defaultProps = {};

export default LottieSublease;
