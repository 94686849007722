import React from 'react';
import styles from './car-pool.module.css';

import Lottie from "lottie-react";
import carpoolAnimation from "./89588-tourists-by-car.json";

const LottieCarPool = () => (
  <div className={styles.LottieCarPool} data-testid="LottieCarPool">
    <Lottie animationData={carpoolAnimation} loop={true} />
  </div>
);

LottieCarPool.propTypes = {};

LottieCarPool.defaultProps = {};

export default LottieCarPool;
