import { Typography } from '@mui/material';
import React from 'react';


const MyprofileTrips = () => (
  <div id='mytrips'>
    <Typography sx={{color: "#0561B8FF", mt: 1}} variant="h6">
      Trips
    </Typography>
    <Typography variant="p">
      No trips, go out somewhere! Enjoy life! (This section is work in progress)
    </Typography>
  </div>
);


export default MyprofileTrips;
