import React, { useEffect, useState } from 'react';
import DocumentMeta from 'react-document-meta';
import { useNavigate } from "react-router-dom";
// import { useSelector } from 'react-redux';
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Container,
  createTheme, 
  ThemeProvider,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Fab,
  Dialog,
  IconButton
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { AddCircle } from '@mui/icons-material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import LottieLoading from '../../components/lottie/loading/lottie/loading';
import axios from 'axios';


import { getValuesFromFlags, MAPBOX_TOKEN } from '../../globals/constants';

import { uploadFilesToStorage, getDownloadURLFromRef, addSubLease, getAllLandlords, saveLandlordDetails, getPropertyOptions, updatePropertyOptions, addTracker } from '../../globals/firebaseConfigs';

const theme = createTheme();
const filter = createFilterOptions();

const meta = {
  title: 'HousingNet | Add listing',
  description: 'Add information about listing and earn points!',
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags,housing,points,rewards'
    }
  }
};

export default function AddListing() {
  const navigate = useNavigate();
  const userInfo = JSON.parse(sessionStorage.getItem("user-info"));

  // const { PROPERTYOPTIONS } = useSelector((state => state.propertyopts));
  const [PROPERTYOPTIONS, setPropertyOptions] = useState({});

  const [waiting, setWaiting] = useState(false);
  const [housenumber, setHouseNumber] = useState('');
  const [street, setStreet] = useState('Tyler');
  const [privacy, setPrivacy] = useState('Private');
  const [gender, setGender] = useState('Male');
  const [monthlyrent, setMonthlyRent] = useState(400);
  const [totalrooms, setTotalRooms] = useState(4);
  const [totalbathrooms, setTotalBathRooms] = useState(1);
  const [totalkitchens, setTotalKitchens] = useState(1);
  const [totalroomsavailable, setTotalRoomsAvailable] = useState(1);
  const [foodpref, setFoodPreference] = useState('Vegetarian');
  const [washerdryer, setWasherDryer] = useState('Included');
  const [subleasestart, setSubleaseStartdate] = React.useState(dayjs());
  const [subleaseend, setSubleaseEnddate] = React.useState(dayjs().add(1, 'month'));
  const [comment, setComment] = useState('');
  const [available, setAvailable] = useState(true);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  const [possibleroominclusionsState, setPossibleRoomInclusionsState] = useState(null);
  const [possiblekitcheninclusionsState, setPossibleKitchenInclusionsState] = useState(null);

  const handlePossibleRoomInclusionsStateChange = (position) => {
    const updatedCheckedState = possibleroominclusionsState.map((item, index) =>
      index === position ? !item : item
    );
    setPossibleRoomInclusionsState(updatedCheckedState);
  };

  const handlePossibleKitchenInclusionsStateChange = (position) => {
    const updatedCheckedState = possiblekitcheninclusionsState.map((item, index) =>
      index === position ? !item : item
    );
    setPossibleKitchenInclusionsState(updatedCheckedState);
  };

  const [uploadedfiles, setUploadedFiles] = React.useState([]);
  const [localuploadedfiles, setLocalUploadedFiles] = React.useState([]);

  // to show landlord options
  const [landlords, setLandlords] = React.useState([]);
  const [showdialoglandlorddetails, toggleOpenDialogLandlordDetails] = React.useState(false);
  const [landlorddialogvalue, setLandlordDialogValue] = React.useState({
    name: '',
    fb: '',
    wa: '',
    ph: '',
    em: '',
    ot: ''
  });
  const [addercontactdetails, setAdderContactDetails] = React.useState({
    name: '',
    fb: '',
    wa: '',
    ph: '',
    em: JSON.parse(sessionStorage.getItem("user-info")).email,
    ot: ''
  });
  const [showadderadditionaloptions, toggleAdderAdditionalOptions] = React.useState(false);

  const [openaddstreetdialog, toggleAddStreetDialog] = React.useState(false);
  const [newstreetname, setNewStreetName] = React.useState('');
  const [openaddhouseinclusiondialog, toggleAddHouseInclusionDialog] = React.useState(false);
  const [newhouseinclusion, setNewHouseInclusion] = React.useState('');
  const [openaddkitcheninclusiondialog, toggleAddKitchenInclusionDialog] = React.useState(false);
  const [newkitcheninclusion, setNewKitchenInclusion] = React.useState('');
  const [geocodingSearchResults, setGeocodingSearchResults] = React.useState([{'place_name': 'Type at least 3 characters.'}]);

  const updateGeocodingOptions = async val => {
    val = (val || '').trim();
    // call mapbox only when there are at least 3 characters typed
    if (val.length > 2){
      // set options loading spinner on
      setGeocodingSearchResults([{'place_name': '...searching...'}]);

      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${val.replaceAll(' ', '%20')}.json?access_token=${MAPBOX_TOKEN}&autocomplete=true&country=us`;
      // console.log(endpoint);

      // call the mapbox API and get the options
      axios.get(endpoint)
      .then(response => {
        const results = response.data;
        const options = results.features;

        // set the geocoding options
        setGeocodingSearchResults(options);
      })
      .catch(error => console.error("Error fetching data, ", error));
    } else {
      setGeocodingSearchResults([{'place_name': 'Type at least 3 characters.'}]);
    }
  }

  const handleAddressSelected = (event, newValue) => {
    // console.log(newValue.address, newValue.text, newValue.center);

    // set the house number
    setHouseNumber(newValue.address);

    // set the street
    setStreet(newValue.text);

    // set the lat lng
    const coords = newValue.center;
    setLng(coords[0]);
    setLat(coords[1]);
  }

  const handleNewPropertySave = (propopttype) => {
    var temppropops = PROPERTYOPTIONS;
    if (propopttype === 'street')
      temppropops.streets.push(newstreetname);
    else if (propopttype === 'houseinclusion') {
      temppropops.possibleroominclusions.push(newhouseinclusion);
      var temp = possibleroominclusionsState;
      temp.push(false);
      setPossibleRoomInclusionsState(temp);
    } else if (propopttype === 'kitcheninclusion') {
      temppropops.possiblekitcheninclusions.push(newkitcheninclusion);
      var temp = possiblekitcheninclusionsState;
      temp.push(false);
      setPossibleKitchenInclusionsState(temp);
    }

    // add to local state
    setPropertyOptions(temppropops);

    // add to firebase state
    updatePropertyOptions(temppropops);

    toggleAddStreetDialog(false);
    toggleAddHouseInclusionDialog(false);
    toggleAddKitchenInclusionDialog(false);
  }

  useEffect( () => {
    addTracker('newlistingpage');
    setWaiting(true);

    Promise.all([getAllLandlords(), getPropertyOptions()])
    .then((results) => {
      setLandlords(Object.values(results[0].val()));

      const temppropops = results[1].val(); 
      setPropertyOptions(temppropops);
      setPossibleRoomInclusionsState(new Array(temppropops.possibleroominclusions.length).fill(false));
      setPossibleKitchenInclusionsState(new Array(temppropops.possiblekitcheninclusions.length).fill(false));
      setWaiting(false);
    });

  }, [setLandlords, setWaiting, setPropertyOptions]);

  const handleCloseLandlordInfoDialog = () => {
    setLandlordDialogValue({
      name: '', fb: '', wa: '', ph: '', em: '', ot: ''
    });
    toggleOpenDialogLandlordDetails(false);
  };

  const handleSubmitLandlordInfoDialog = (event) => {
    event.preventDefault();
    setLandlords([...landlords, landlorddialogvalue]);
    saveLandlordDetails(landlorddialogvalue).then(() => {
      handleCloseLandlordInfoDialog();
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addTracker('submitnewlisting');
    setWaiting(true);

    var leasedata = {
      housenumber: housenumber,
      street: street,
      privacy: privacy,
      gender: gender,
      totalroomsavailable: totalroomsavailable,
      monthlyrent: monthlyrent,
      totalrooms: totalrooms,
      totalbathrooms: totalbathrooms,
      totalkitchens: totalkitchens,
      subleasestart: available ? subleasestart.format('DD MMM YYYY'): '',
      subleaseend: subleaseend.format('DD MMM YYYY'),
      foodpref: foodpref,
      washerdryer: washerdryer,
      roominclusions: getValuesFromFlags(possibleroominclusionsState, PROPERTYOPTIONS.possibleroominclusions),
      kitcheninclusions: getValuesFromFlags(possiblekitcheninclusionsState, PROPERTYOPTIONS.possiblekitcheninclusions),
      comment: comment,
      addeddate: dayjs().toString(),
      addedby: userInfo.uid,
      landlord: landlorddialogvalue,
      addercontactdetails: addercontactdetails,
      isavailable: available,
      lat: lat,
      lng: lng
    };

    // step-1: upload files to firebase storage
    const uploadpromises = uploadFilesToStorage(uploadedfiles, '/property/' + street + '/' + housenumber);
    Promise.all(uploadpromises).then((uploadsnaps) => {
      // step-2: get url for the uploaded files
      const urlpromises = uploadsnaps.map(snap => getDownloadURLFromRef(snap.ref));
      Promise.all(urlpromises).then(urls => {
        // step-3: add urls to data object
        leasedata['imagefiles'] = urls;
      }).then(() => {
        // step-4: add data to firebase db
        return addSubLease(leasedata);
      }).then(() => {
        // TODO: add points for current user
      }).then(() => {
        // step-5: redirect to property page
        setWaiting(false);
        navigate("/leaselistings");
      }).catch(err => alert(err));
    });
  };

  return (
    <DocumentMeta {...meta}>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Add your sublease
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  disablePortal
                  id="grocoding-results"
                  options={geocodingSearchResults}
                  getOptionLabel={(option) => option.place_name}
                  // onSelect={(e) => {console.log(e.target.value)}}
                  renderOption={(props, option) => <li {...props}>{option.place_name}</li>}
                  renderInput={(params) => <TextField {...params} label="Address" onChange={(event) => setTimeout(() => {updateGeocodingOptions(event.target.value)})} />}
                  filterOptions={(options) => options}
                  onChange={handleAddressSelected}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="housenumber"
                  label="House number"
                  name="housenumber"
                  value={housenumber}
                  autoComplete="housenumber"
                  onChange={(event) => setHouseNumber(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="streetlabel">Street/Avenue</InputLabel>
                  <Select
                    labelId="streetlabel"
                    id="street"
                    value={street}
                    label="Street/Avenue"
                    onChange={(event) => setStreet(event.target.value)}
                  >
                    {
                      (PROPERTYOPTIONS.streets || []).map((item, idx) => {
                        return (<MenuItem key={idx} value={item}>{item}</MenuItem>)
                      })
                    }
                    <Button color='primary' onClick={() => toggleAddStreetDialog(true)}>Add new street</Button>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="privacylabel">Privacy</InputLabel>
                  <Select
                    labelId="privacylabel"
                    id="privacy"
                    value={privacy}
                    label="Privacy"
                    onChange={(event) => setPrivacy(event.target.value)}
                  >
                    {
                      (PROPERTYOPTIONS.roomprivacyoptions || [] ).map((item, idx) => {
                        return (<MenuItem key={idx} value={item}>{item}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="genderlabel">Available For Gender</InputLabel>
                  <Select
                    labelId="genderlabel"
                    id="gender"
                    name="gender"
                    value={gender}
                    label="Available For Gender"
                    onChange={(event) => setGender(event.target.value)}
                  >
                    {
                      (PROPERTYOPTIONS.genders || [] ).map((item, idx) => {
                        return (<MenuItem key={idx} value={item}>{item}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="totalroomsavailablelabel">Rooms Available for sublease</InputLabel>
                  <Select
                    labelId="totalroomsavailablelabel"
                    id="totalroomsavailable"
                    value={totalroomsavailable}
                    label="Rooms Available for sublease"
                    onChange={(event) => setTotalRoomsAvailable(event.target.value)}
                  >
                    {
                      (PROPERTYOPTIONS.roomcounts || [] ).map((item, idx) => {
                        return (<MenuItem key={idx} value={item}>{item}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="monthlyrent"
                  label="Monthly rent"
                  name="monthlyrent"
                  autoComplete="monthlyrent"
                  value={monthlyrent}
                  onChange={(event) => setMonthlyRent(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="totalroomslabel">Total rooms</InputLabel>
                  <Select
                    labelId="totalroomslabel"
                    id="totalrooms"
                    value={totalrooms}
                    label="Total rooms"
                    onChange={(event) => setTotalRooms(event.target.value)}
                  >
                    {
                      (PROPERTYOPTIONS.roomcounts || [] ).map((item, idx) => {
                        return (<MenuItem key={idx} value={item}>{item}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="totalbathroomslabel">Total bathrooms</InputLabel>
                  <Select
                    labelId="totalbathroomslabel"
                    id="totalbathrooms"
                    value={totalbathrooms}
                    label="Total bathrooms"
                    onChange={(event) => setTotalBathRooms(event.target.value)}
                  >
                    {
                      (PROPERTYOPTIONS.bathroomcounts || [] ).map((item, idx) => {
                        return (<MenuItem key={idx} value={item}>{item}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="totalkitchenslabel">Total kitchens</InputLabel>
                  <Select
                    labelId="totalkitchenslabel"
                    id="totalkitchens"
                    value={totalkitchens}
                    label="Total kitchens"
                    onChange={(event) => setTotalKitchens(event.target.value)}
                  >
                    {
                      (PROPERTYOPTIONS.kitchencounts || [] ).map((item, idx) => {
                        return (<MenuItem key={idx} value={item}>{item}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
              >
                <FormControlLabel xs={12}
                  control={<Checkbox value="remember" color="primary" onChange={(event) => setAvailable(!event.target.checked)}/>}
                  label="Not available to lease right now"
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{display:'flex'}}>
                {available && <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Start date"
                    inputFormat="MM/DD/YYYY"
                    value={subleasestart}
                    onChange={(newDate) => setSubleaseStartdate(newDate)}
                    renderInput={(params) => <TextField {...params} fullWidth/>}
                  />
                </LocalizationProvider>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label={available ? "End date" : "Current lease end date"}
                    inputFormat="MM/DD/YYYY"
                    value={subleaseend}
                    onChange={(newDate) => setSubleaseEnddate(newDate)}
                    renderInput={(params) => <TextField {...params} fullWidth/>}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="foodpreflabel">Food preferences</InputLabel>
                  <Select
                    labelId="foodpreflabel"
                    id="foodpref"
                    value={foodpref}
                    label="Food preferences"
                    onChange={(event) => setFoodPreference(event.target.value)}
                  >
                    {
                      (PROPERTYOPTIONS.foodpreferences || [] ).map((item, idx) => {
                        return (<MenuItem key={idx} value={item}>{item}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="washerdryerlabel">Washer/Dryer</InputLabel>
                  <Select
                    labelId="washerdryerlabel"
                    id="washerdryer"
                    value={washerdryer}
                    label="Washer/Dryer"
                    onChange={(event) => setWasherDryer(event.target.value)}
                  >
                    {
                      (PROPERTYOPTIONS.washerdryeroptions || [] ).map((item, idx) => {
                        return (<MenuItem key={idx} value={item}>{item}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <fieldset>
                  <legend>Included in the house</legend>
                  {
                    (PROPERTYOPTIONS.possibleroominclusions || [] ).map((item, idx) => {
                      return (<FormControlLabel
                        label={item}
                        key={idx}
                        control={<Checkbox 
                          value={item} 
                          color="success" 
                          checked={possibleroominclusionsState[idx]} 
                          onChange={() => handlePossibleRoomInclusionsStateChange(idx)} /> 
                        }
                      />)
                    })
                  }
                  <IconButton color="primary" aria-label="add room inclusion" onClick={() => toggleAddHouseInclusionDialog(true)}>
                    <AddCircle />
                  </IconButton>
                </fieldset>
              </Grid>
              <Grid item xs={12}>
                <fieldset>
                  <legend>Included in the kitchen</legend>
                  {
                    (PROPERTYOPTIONS.possiblekitcheninclusions || [] ).map((item, idx) => {
                      return (<FormControlLabel
                        label={item}
                        key={idx}
                        control={<Checkbox 
                          value={item} 
                          color="success" 
                          checked={possiblekitcheninclusionsState[idx]} 
                          onChange={() => handlePossibleKitchenInclusionsStateChange(idx)} /> 
                        }                      
                      />)
                    })
                  }
                  <IconButton color="primary" aria-label="add room inclusion" onClick={() => toggleAddKitchenInclusionDialog(true)}>
                    <AddCircle />
                  </IconButton>
                </fieldset>
              </Grid>
              <Grid item xs={12} sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'none'
                  }}
                >
                  <input
                    accept="image/*"
                    id="imageupload"
                    multiple
                    type="file"
                    onChange={(event) => { 
                      // set files to upload
                      setUploadedFiles(event.target.files);
                      // set files for preview
                      setLocalUploadedFiles(Array.from(event.target.files).map(f => URL.createObjectURL(f))); 
                    }}
                    className='hidden'
                  />
                </Box>
                <label htmlFor="imageupload">
                  <Fab component="span" >
                    <AddPhotoAlternateIcon />
                  </Fab>
                </label>
              </Grid>
              <Grid item xs={12} sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
              >
                <fieldset>
                <legend>Uploaded images (preview)</legend>
                {
                  localuploadedfiles.map((item, idx) => {
                    return (<img key={idx} src={item} width={95} height={95} alt={idx} />)
                  })
                }
                </fieldset>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={2}
                  id="comments"
                  label="Comments/Reviews"
                  value={comment}
                  onChange={(event) => setComment(event.target.value)}
                  autoComplete="comments"
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={landlorddialogvalue}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      // timeout to avoid instant validation of the dialog's form.
                      setTimeout(() => {
                        toggleOpenDialogLandlordDetails(true);
                        setLandlordDialogValue({
                          name: newValue,
                          fb: '',
                          wa: '',
                          ph: '',
                          em: '',
                          ot: ''
                        });
                      });
                    } else if (newValue && newValue.inputValue) {
                      toggleOpenDialogLandlordDetails(true);
                      setLandlordDialogValue({
                        name: newValue.inputValue,
                        fb: '',
                        wa: '',
                        ph: '',
                        em: '',
                        ot: ''
                      });
                    } else {
                      setLandlordDialogValue(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  id="free-solo-dialog-demo"
                  options={landlords}
                  getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.name;
                  }}
                  selectOnFocus
                  clearOnBlur
                  fullWidth
                  handleHomeEndKeys
                  renderOption={(props, option) => <li {...props}>{option.name}</li>}
                  sx={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => <TextField fullWidth {...params} label="Landlord" InputProps={{...params.InputProps, endAdornment: (null) }}/>}
                />
              </Grid>
              <Grid item xs={12} sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <FormControlLabel xs={12}
                  control={<Checkbox value="remember" color="primary" onChange={(event) => toggleAdderAdditionalOptions(event.target.checked)}/>}
                  label="Students may contact me for more details"
                />
                {showadderadditionaloptions ? (
                <fieldset>
                  <legend>My contact options</legend>
                  <TextField
                    margin="dense"
                    id="fb"
                    value={addercontactdetails.fb}
                    onChange={(event) =>
                      setAdderContactDetails({
                        ...addercontactdetails,
                        fb: event.target.value,
                      })
                    }
                    label="facebook.com/[THIS ID]"
                    type="text"
                  />
                  <PhoneInput
                    country={'us'}
                    onChange={(value) =>
                      setAdderContactDetails({
                        ...addercontactdetails,
                        ph: value,
                      })
                    }
                    specialLabel="Phone"
                    enableSearch
                    id="ph"
                  />
                  <PhoneInput
                    country={'us'}
                    onChange={(value) =>
                      setAdderContactDetails({
                        ...addercontactdetails,
                        wa: value,
                      })
                    }
                    specialLabel="Whatsapp"
                    enableSearch
                    id="wa"
                  />
                  <TextField
                    disabled
                    margin="dense"
                    id="em"
                    value={addercontactdetails.em}
                    label="Email"
                    type="text"
                  />
                  <TextField
                    margin="dense"
                    id="other"
                    value={addercontactdetails.ot}
                    onChange={(event) =>
                      setAdderContactDetails({
                        ...addercontactdetails,
                        ot: event.target.value,
                      })
                    }
                    label="Other"
                    type="text"
                  />
                  </fieldset>) : null}
              </Grid>
            </Grid>
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Add Sublease
            </Button>
          </Box>
        </Box>
      </Container>
      <Dialog
        open={waiting}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <LottieLoading />
      </Dialog>
      <Dialog open={showdialoglandlorddetails} onClose={handleCloseLandlordInfoDialog}>
        <DialogTitle>Do you have more details about this landlord?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={landlorddialogvalue.name}
            onChange={(event) =>
              setLandlordDialogValue({
                ...landlorddialogvalue,
                name: event.target.value,
              })
            }
            label="Name"
            type="text"
          />
          <TextField
            margin="dense"
            id="fb"
            value={landlorddialogvalue.fb}
            onChange={(event) =>
              setLandlordDialogValue({
                ...landlorddialogvalue,
                fb: event.target.value,
              })
            }
            label="Facebook profile"
            type="text"
          />
          <TextField
            margin="dense"
            id="other"
            value={landlorddialogvalue.ph}
            onChange={(event) =>
              setLandlordDialogValue({
                ...landlorddialogvalue,
                ph: event.target.value,
              })
            }
            label="Phone"
            type="text"
          />
          <TextField
            margin="dense"
            id="wa"
            value={landlorddialogvalue.wa}
            onChange={(event) =>
              setLandlordDialogValue({
                ...landlorddialogvalue,
                wa: event.target.value,
              })
            }
            label="Whatsapp"
            type="text"
          />
          <TextField
            margin="dense"
            id="em"
            value={landlorddialogvalue.em}
            onChange={(event) =>
              setLandlordDialogValue({
                ...landlorddialogvalue,
                em: event.target.value,
              })
            }
            label="Email"
            type="text"
          />
          <TextField
            margin="dense"
            id="other"
            value={landlorddialogvalue.ot}
            onChange={(event) =>
              setLandlordDialogValue({
                ...landlorddialogvalue,
                ot: event.target.value,
              })
            }
            label="Other"
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLandlordInfoDialog}>Cancel</Button>
          <Button type="submit" onClick={handleSubmitLandlordInfoDialog}>Add</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openaddstreetdialog} onClose={() => toggleAddStreetDialog(false)}>
        <DialogTitle>Add Street to list</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            value={newstreetname}
            id="streetname"
            label="Street name"
            type="text"
            onChange={(event) => setNewStreetName(event.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleAddStreetDialog(false)}>Cancel</Button>
          <Button onClick={() => handleNewPropertySave('street')}>Add</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openaddhouseinclusiondialog} onClose={() => toggleAddHouseInclusionDialog(false)}>
        <DialogTitle>Add new house inclusion to list</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            value={newhouseinclusion}
            id="houseinclusion"
            label="Inclusion title"
            type="text"
            onChange={(event) => setNewHouseInclusion(event.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleAddHouseInclusionDialog(false)}>Cancel</Button>
          <Button onClick={() => handleNewPropertySave('houseinclusion')}>Add</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openaddkitcheninclusiondialog} onClose={() => toggleAddKitchenInclusionDialog(false)}>
        <DialogTitle>Add new kitchen inclusion to list</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            value={newkitcheninclusion}
            id="kitcheninclusion"
            label="Inclusion title"
            type="text"
            onChange={(event) => setNewKitchenInclusion(event.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleAddKitchenInclusionDialog(false)}>Cancel</Button>
          <Button onClick={() => handleNewPropertySave('kitcheninclusion')}>Add</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
    </DocumentMeta>
  );
}