import { VALID_EMAIL_DOMAINS, BLOCKED_EMAILS, FIREBASE_ERROR_ABSTRACTED } from './constants';

export function validateEmail(email) {
  // check for blocked emails
  if(BLOCKED_EMAILS.includes(email)){
    alert('This email is blocked due to suspecious activities! Please send an email to mandeepv@buffalo.edu if you think you were blocked incorrectly.');
    return false;
  }

  // checks is email is of valid domain by checking the trailing characters
  for (const domain of Object.keys(VALID_EMAIL_DOMAINS)) {
    if (email.toLowerCase().endsWith(domain)) return true;
  }

  // if no matching domain was found return false
  return false;
}

export function extractLatestOTP(data) {
  // get check OTPs only valid, ie expiry_time is later than now
  var maxDate = new Date(new Date().getTime() - 1 * 60000);
  var latestOTP = '';

  // iterate each OTP for the email
  for (const item in data) {
    var otpObj = data[item];
    var itemExpiryDate = new Date(otpObj.expiry_time);
    // if the expiry_date is latest update latestOTP var
    if (maxDate < itemExpiryDate) {
      maxDate = itemExpiryDate;
      latestOTP = otpObj.otp;
    }
  }
  return latestOTP.toString();
};

export function getUserFriendlyAuthError(key){
  if(FIREBASE_ERROR_ABSTRACTED.hasOwnProperty(key)){
    return FIREBASE_ERROR_ABSTRACTED[key]
  } else {
    return "Unrecognized error, please add feedback or email mandeepv@buffalo.edu"
  }
}