import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@mui/material';
import { getLeasesByUserID } from '../../../../globals/firebaseConfigs';
import EditIcon from '@mui/icons-material/Edit';

const MyprofileLeaselistings = () => {

  const [leases, setLeases] = useState([]);

  useEffect(() => {
    getLeasesByUserID(sessionStorage.getItem("uid")).then((snap) => {
      setLeases(snap.val());
    });
  }, []);

  return (
    <div id='myleases' >
      <Typography sx={{color: "#0561B8FF", mt: 3}} variant="h6">
        My Leases
      </Typography>
      {
        leases ? 
        (
          Object.keys(leases).map(key => <Button variant="contained" endIcon={<EditIcon />} key={key}>
            {leases[key].housenumber + ' ' + leases[key].street}
          </Button>)
        ) :
        (
          <Typography variant="h6">
            Please add your current lease, it will help a lot of people!
          </Typography>
        )
      }
      <Button
        type="submit"
        variant="contained"
        href="/addlisting"
        color='success'
      >
        Add New Lease
      </Button>
    </div>
  );
}

export default MyprofileLeaselistings;
