import { 
  Grid, 
  Typography, 
  TextField, 
  InputAdornment, 
  InputLabel, 
  Select, 
  MenuItem, 
  FormControl,
  Button
} from "@mui/material";
import React from "react";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const FilterSection = () => {
  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} md={4}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "18px", marginBottom: "10px", fontWeight: 'bold' }}>
            Search
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="filled-adornment-password"
            label="Keyword"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ManageSearchIcon />
                </InputAdornment>
              ),
            }}
            variant="filled"
            sx={{pb: 1}}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} md={4}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "18px", fontWeight: 'bold' }}>
            Filter
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="filled" fullWidth sx={{pl: 1, pr: 1}}>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={10}
              label="Category"
              // onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="filled" fullWidth sx={{pl: 1, pr: 1}}>
            <InputLabel id="demo-simple-select-label2">Branch</InputLabel>
            <Select
              labelId="demo-simple-select-label2"
              id="demo-simple-select2"
              value={10}
              label="Branch"
              // onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={2}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "18px", marginBottom: "10px", fontWeight: 'bold' }}>
            Sort By
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth sx={{pb: 1}}>
            <InputLabel id="demo-simple-select-label2">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label2"
              id="demo-simple-select2"
              value={10}
              label="Age"
              // onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={2}>
        <Grid item xs={12} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }}}>&nbsp;</Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="success"
          >
            Ask Question
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterSection;
