import React from 'react';
import styles from './success.module.css';

import Lottie from "lottie-react";
import successAnimation from "./96237-success.json";

const LottieSuccess = () => (
  <div className={styles.LottieSuccess} data-testid="LottieSuccess">
    <Lottie animationData={successAnimation} loop={true} />
  </div>
);

LottieSuccess.propTypes = {};

LottieSuccess.defaultProps = {};

export default LottieSuccess;
