import React from 'react';
import styles from './community.module.css';

import Lottie from "lottie-react";
import communityAnimation from "./91021-leadership-talents.json";

const LottieCommunity = () => (
  <div className={styles.LottieCommunity} data-testid="LottieCommunity">
    <Lottie animationData={communityAnimation} loop={true} />
  </div>
);

LottieCommunity.propTypes = {};

LottieCommunity.defaultProps = {};

export default LottieCommunity;
