import React from 'react';
import Box from "@mui/material/Box";
import Cone from "../../assets/images/conev1.jpg";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Slide from '@mui/material/Slide';

const CouponCard = () => {
  const [checked, setChecked] = React.useState(false);
  const containerRef = React.useRef(null);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        "&:hover": {
          opacity: [0.9, 0.8, 0.7],
        },
        padding: "1rem",
        backgroundImage: `url(${Cone})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        objectFit: "cover",
        aspectRatio: "auto",
        border: "5px solid whitesmoke",
        outline: "5px solid lightgray",
        zIndex: 0
      }}

      onMouseEnter={() => setChecked(true)}
      onMouseLeave={() => setChecked(false)}
      ref={containerRef}
    >
      <Typography variant="h4" gutterBottom>
        Aldi's Store
      </Typography>
      <Chip label="30% off" color="success" />
      <Typography variant="body2" mb={5} gutterBottom>
        Get cheaper grocceries!
      </Typography>
      <Button variant="contained" color='success' sx={{ display: { xs: 'flex', md: 'none' }}}>
        Accept Reward
      </Button>
      <Slide direction="up" in={checked} container={containerRef.current} sx={{ display: { xs: 'none', md: 'flex' }}}>
        <Button variant="contained" color='success'>
          Accept Reward
        </Button>
      </Slide>
    </Box>
  );
}

export default CouponCard;
