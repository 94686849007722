import Graph from "react-graph-vis";
import React, { useState } from "react";
import axios from "axios";

import {
  CssBaseline,
  Box,
  Container,
  createTheme,
  ThemeProvider,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
  Button,
  Dialog,
  Divider,
  DialogContentText,
  DialogActions,
  DialogTitle,
  DialogContent,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';

import LottieLoading from '../../components/lottie/loading/lottie/loading';
import DocumentMeta from 'react-document-meta';
import LottieSuccess from '../../components/lottie/success/lottie/success';

import { useEffect } from "react";
import { addCourseExchangeMapping, getCourseList, getCourseMappingRef, addUserInfo, addTracker } from "../../globals/firebaseConfigs";
import dayjs from "dayjs";
import { onChildAdded } from "firebase/database";
import { AWSLAMBDA_CYCLEDETECTORURL } from '../../globals/constants';

const theme = createTheme();

const options = {
  layout: {
    hierarchical: false
  },
  edges: {
    color: "#000000"
  }
};

const meta = {
  title: 'HousingNet | CourseXchange',
  description: 'Improve your chances, get the course you always wanted!',
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags,housing'
    }
  }
};

function randomColor() {
  const red = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
  const green = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
  const blue = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
  return `#${red}${green}${blue}`;
}

const CourseExchange = () => {

  const [waiting, setWaiting] = useState(false);
  const [errormessage, setErrorMessage] = useState('');
  const [showsuccess, showSuccessModel] = useState(false);
  const [X, setX] = useState([]);
  const [Y, setY] = useState([]);
  const [conditionnotacceptederror, setError] = useState(true);
  const [courselist, setCourseList] = useState([]);
  const [graph, setGraph] = useState({
    nodes: [],  // this list will remain hardcoded in db
    edges: [] // will be dynamically added and fetched from DB
  });

  // get the current logged in user info
  const userinfo = JSON.parse(sessionStorage.getItem("user-info")); // TODO: use redux for this everywhere
  const [branch, setBranch] = useState('MIS'); // TODO: use redux for this everywhere
  const [batch, setBatch] = useState('Fall22'); // TODO: use redux for this everywhere
  const [showdatacollectordialog, showDataCollectorDialog] = useState(false);
  const [cyclestatus, setCycleStatus] = useState('');

  const getExhangeCycleStatus = () => {
    axios.get(AWSLAMBDA_CYCLEDETECTORURL).then((response) => {
      // console.log(response.data[branch]);
      setCycleStatus(response.data[branch]);
    });
  }
  const enterIntoExchange = () => {
    if (!conditionnotacceptederror) {
      setErrorMessage('');
      var mappingadditionpromises = [];

      // get all combinations of X-Y
      X.forEach(x => {
        Y.forEach(y => {
          if (x !== y) {
            // add data to firebase database
            var mapping = {
              from: x.id,
              to: y.id,
              time: dayjs().toString(),
              uuid: userinfo.uid
            }
            mappingadditionpromises.push(addCourseExchangeMapping(mapping, branch));
          } else {
            setErrorMessage('Can\'t give and take the same course :/');
          }
        });
      });
      
      // execute all promises
      Promise.all(mappingadditionpromises).then(() => {
        showSuccessModel(true);
      }); // TODO: add error handling
    } else {
      setErrorMessage('Error: You have not accepted the condition!');
    }
  }

  const handleClose = () => {
    showDataCollectorDialog(branch=='undefined' || batch=='undefined' || !batch || !branch);
  };

  const handleSave = (type, val) => {
    var info = {};
    // save data to session store
    if (type==='branch'){
      sessionStorage.setItem("branch", val);
      info = {branch: val, batch: batch}
      setBranch(val);
    }

    if (type==='batch'){
      sessionStorage.setItem("batch", val);
      info = {branch: branch, batch: val};
      setBatch(val);
    }

    // save data to firebase
    addUserInfo(userinfo.uid, info).then(() => {
      showDataCollectorDialog(branch=='undefined' || batch=='undefined' || !batch || !branch);
    });
  }

  useEffect(() => {
    setWaiting(true);
    addTracker('courseexchangepage');
    getExhangeCycleStatus();
    
    const tempbranch = sessionStorage.getItem("branch");
    const tempbatch = sessionStorage.getItem("batch");
    setBatch(tempbatch);
    setBranch(tempbranch);
    showDataCollectorDialog(tempbranch=='undefined' || tempbatch=='undefined' || !tempbatch || !tempbranch);

    // get courselist
    getCourseList(branch).then(snap => {
      var tempcourselist = []; // init an empty couse list
      const courses = snap.val() || [];
      
      // construct the courselist in expected format [{ id: 1, label: "Node 1", color: "#e04141" },...]
      courses.forEach((coursedetail) => {
        tempcourselist.push({
          id: coursedetail.id,
          label: coursedetail.code + ' ' + coursedetail.prof.slice(0, 4) + '\n' + coursedetail.time,
          color: randomColor(),
          fullname: coursedetail.full,
          comname: '[' + coursedetail.id + ' ' + coursedetail.code + '] ' + coursedetail.full + '(' + coursedetail.time + ') ' + '(' + coursedetail.prof + ')'
        });
      });
      
      setGraph({nodes: graph.nodes, edges: graph.edges});
      setCourseList(tempcourselist);
      return tempcourselist
    }).then(tempcourselist => {
      onChildAdded(getCourseMappingRef(branch), snapshot => {
        // everytime a mapping is added in db we need to add the mapping in the graph
        setGraph(({ nodes, edges }) => {
          // filter the courselist and keep only the ones that are present in the mappings
          var nodesinmapping = new Set();
          const mappings = [...edges, snapshot.val()];
          mappings.map(m => {
            nodesinmapping.add(m.from);
            nodesinmapping.add(m.to);
          });
          const filteredcourselist = tempcourselist.filter(c => nodesinmapping.has(c.id));

          return {
            nodes: [...filteredcourselist],
            edges: [...mappings]
          }
        });
      });
    }).then(() => {
      setWaiting(false);
    });

  }, [setWaiting, randomColor, setGraph, branch, batch]);

  return (
    <DocumentMeta {...meta}>
    <ThemeProvider theme={theme}>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 1
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography variant="h4" sx={{mt: 5}}>
                Course Exchange
              </Typography>
              <Typography variant="h6" sx={{mt: 1}}>
                Branch: {branch}
              </Typography>
              <Typography variant="h6">
                Batch: {batch}
              </Typography>
              <FormControlLabel xs={12}
                sx={{mt: 5}}
                control={<Checkbox
                  value="accept"
                  color="success"
                  onChange={(event) => {setError(!event.target.checked); setErrorMessage('');}}
                />}
                label={<Typography color={conditionnotacceptederror ? "error" : "default"}>I agree to share my contact to others in the community when a match cycle is identified.</Typography>}
                color="error"
              />
              <Autocomplete
                multiple
                id="take"
                sx={{mt: 5}}
                options={courselist}
                getOptionLabel={(option) => option.comname}
                onChange={(e, v) => {setX(v); setErrorMessage('');}}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Courses I need"
                    placeholder="Select courses"
                  />
                )}
              />
              <Autocomplete
                multiple
                id="give"
                sx={{mt: 2}}
                options={courselist}
                getOptionLabel={(option) => option.comname}
                onChange={(e, v) => {setY(v); setErrorMessage('');}}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Courses I can give"
                    placeholder="Select courses"
                  />
                )}
              />
              <Button
                fullWidth
                sx={{mt: 2}}
                variant="contained"
                color='primary'
                onClick={enterIntoExchange}
              >
                Enter into exchange
              </Button>
              {
                errormessage && (<>
                  <Divider sx={{mt: 2}} />
                  <Typography variant="p" sx={{mt: 1}} color="error">
                    {errormessage}
                  </Typography>
                </>)
              }
              <Divider sx={{mt: 2}} />
              <Typography variant="p" sx={{mt: 2}}>
                For missing courses drop a message to &nbsp;
                 <a href={"mailto:mandeepv@buffalo.edu?subject=CourseAddition&body=Hi%2C%0D%0APlease%20add%20following%20course."} target="_blank" rel="noopener noreferrer">mandeepv@buffalo.edu</a>
                 &nbsp;or whatsapp 
                 &nbsp;<a href={"https://wa.me/+919969315284"} target="_blank" rel="noopener noreferrer">+919969315284</a>
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="h6">
                {cyclestatus} for {branch}
              </Typography>
              <Graph graph={graph} options={options} style={{ height: "640px"}} />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Dialog
        open={waiting}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <LottieLoading />
      </Dialog>
      <Dialog open={showdatacollectordialog} onClose={handleClose} >
        <DialogTitle>Hey There!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To use this tool and serve you better we need to your batch and branch detail.
          </DialogContentText>
          <FormControl fullWidth sx={{mt: 2}}>
            <InputLabel id="batch-select-label">Batch</InputLabel>
            <Select
              labelId="batch-select-label"
              id="batch-select"
              value={batch}
              label="Batch"
              onChange={(event) => handleSave('batch', event.target.value)}
            >
              <MenuItem value={'Fall22'}>Fall22</MenuItem>
              <MenuItem value={'Spring23'}>Spring23</MenuItem>
              <MenuItem value={'Fall23'}>Fall23</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{mt: 2}}>
            <InputLabel id="branch-select-label">Branch</InputLabel>
            <Select
              labelId="branch-select-label"
              id="branch-select"
              value={branch}
              label="Branch"
              onChange={(event) => handleSave('branch', event.target.value)}
            >
              <MenuItem value={'MIS'}>MIS</MenuItem>
              <MenuItem value={'CS'}>CS</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} >Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showsuccess}
        onClose={()=>showSuccessModel(false)} 
        aria-describedby="alert-dialog-slide-description"
      >
        <LottieSuccess />
        <Typography component="p" align='center'>
          You have successfully entered the exchange. Thanks!
        </Typography>
      </Dialog>
    </ThemeProvider>
    </DocumentMeta>
  );
}

export default CourseExchange;