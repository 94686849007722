import React, { useEffect, useState } from 'react';
import DocumentMeta from 'react-document-meta';
import { useParams } from "react-router-dom";
import {
  CssBaseline,
  Box,
  Container,
  createTheme,
  ThemeProvider,
  Grid,
  Dialog
} from '@mui/material';
import LeaseListingCard from '../../components/lease-listing-card/lease-listing-card';
import { getSubleaseDetails, addTracker } from '../../globals/firebaseConfigs';
import LottieLoading from '../../components/lottie/loading/lottie/loading';

const theme = createTheme();
const meta = {
  title: 'HousingNet | See Listing',
  description: 'Get complete details on the listing!',
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags,housing,data,details,premium'
    }
  }
};

const ShowListing = () => {

  const [listings, setListings] = useState({});
  const [waiting, setWaiting] = useState(false);

  const params = useParams();
  const listingid = params.listingid;

  useEffect(() => {
    addTracker('individuallisting'+listingid);
    setWaiting(true);

    getSubleaseDetails(listingid)
    .then((snap) => {
      const data = {listingid: snap.val()};
      setListings(data);
      setWaiting(false);
    });

  }, [setListings, setWaiting]);


  return (
    <DocumentMeta {...meta}>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
              {
                Object.keys(listings).length > 0 ? (Object.entries(listings).map((val, key) => (<LeaseListingCard listingdata={val} key={key}/>))) : "Nothing"
              }
              </Box>
            </Grid>
          </Grid>
          
        </Box>       
      </Container>
      <Dialog
        open={waiting}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <LottieLoading />
      </Dialog>
    </ThemeProvider>
    </DocumentMeta>
  );
}

export default ShowListing;