import { Container } from '@mui/system';
import React, {useEffect} from 'react';
import { addTracker } from '../../globals/firebaseConfigs';
import Lottie404 from '../../components/lottie/404/lottie/404';
// import styles from './page-not-found.module.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

const theme = createTheme();

const PageNotFound = () => {

  useEffect(() => addTracker('notfoundpage'));

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Lottie404 />
          <Typography component="h1" variant="h6">
            Page Not Found
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

PageNotFound.propTypes = {};

PageNotFound.defaultProps = {};

export default PageNotFound;
