import * as React from 'react';
import { Link, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import PersonPinCircle from '@mui/icons-material/PersonPinCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShowWhenAuthenticated from '../../routers/ShowWhenAuthenticated';
import { logoutUserAndDeleteSession } from '../../globals/firebaseConfigs';
import { useEffect } from 'react';

// const settingitems = [{
//     title: 'Profile',
//     link: '/myprofile'
//   }, {
//     title: 'Logout',
//     link: '/logout'
//   }];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [navitems, setNavItems] = React.useState([]);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    const logoutsuccess = logoutUserAndDeleteSession();
    if (logoutsuccess) {
      navigate("/login");
      handleCloseUserMenu();
    }
  }

  useEffect(() => {
    setNavItems([{
      title: 'Subleases',
      link: '/leaselistings',
      authrequire: true
    },
    {
      title: 'CourseXchange',
      link: '/coursexchange',
      authrequire: true
    },
    {
      title: 'Family Plans',
      link: '/family',
      authrequire: true
    },
    {
      title: 'QnA Board',
      link: '/qna',
      authrequire: null
    },
    {
      title: 'Rewards',
      link: '/coupons',
      authrequire: true
    },
    {
      title: 'About Us',
      link: '/aboutus',
      authrequire: null
    }, {
      title: 'Subscribe to Updates',
      link: '/subscribe',
      authrequire: false
    }, {
      title: 'Login',
      link: '/login',
      authrequire: false
    }, {
      title: 'Create Account',
      link: '/signup',
      authrequire: false
    }]);
  }, [setNavItems]);

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <PersonPinCircle sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            HousingNet
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {navitems.map((page) => (
                <ShowWhenAuthenticated authrequire={page.authrequire} key={page.title}>
                  <Link to={page.link} style={{ textDecoration: 'none' }}>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page.title}</Typography>
                    </MenuItem>
                  </Link>
                </ShowWhenAuthenticated>
              ))}
            </Menu>
          </Box>
          <PersonPinCircle sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            HousingNet
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'right' }}>
            {navitems.map((page) => (
              <ShowWhenAuthenticated authrequire={page.authrequire} key={page.title}>
                <Link to={page.link} style={{ textDecoration: 'none' }}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block', paddingRight: 5 }}
                  >
                    {page.title}
                  </Button>
                </Link>
              </ShowWhenAuthenticated>
            ))}
          </Box>

          <ShowWhenAuthenticated authrequire={true}>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Profile Options">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircleIcon />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/* {settingitems.map((setting) => (
                  <Link to={setting.link} style={{ textDecoration: 'none' }}>
                    <MenuItem key={setting.title} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting.title}</Typography>
                    </MenuItem>
                  </Link>
                ))} */}
                <Link to='/myprofile' style={{ textDecoration: 'none' }}>
                  <MenuItem key='myprofile' onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">My Profile</Typography>
                  </MenuItem>
                </Link>
                <MenuItem key='logout' onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </ShowWhenAuthenticated>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;