import React from 'react';
import styles from './search-home.module.css';

import Lottie from "lottie-react";
import searchhomeAnimation from "./67353-search-property.json";

const LottieSearchHome = () => (
  <div className={styles.LottieSearchHome} data-testid="LottieSearchHome">
    <Lottie animationData={searchhomeAnimation} loop={true} />
  </div>
);

LottieSearchHome.propTypes = {};

LottieSearchHome.defaultProps = {};

export default LottieSearchHome;
