// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getDatabase, ref, push, get, set, orderByChild, query, equalTo, update } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { HOSTNAME, VALID_EMAIL_DOMAINS } from "./constants";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBb86JT4y_zskj9LPrXdf-5h2r_31INRRc",
  authDomain: "housingnet-55ff8.firebaseapp.com",
  projectId: "housingnet-55ff8",
  storageBucket: "housingnet-55ff8.appspot.com",
  messagingSenderId: "581642178112",
  appId: "1:581642178112:web:e11d6457f7af9f12b859a5",
  measurementId: "G-E2PVNQPJV2",
  databaseURL: "https://housingnet-55ff8-default-rtdb.firebaseio.com",
};

// Initialize Firebase
// eslint-disable-next-line
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const firebaseAuth = getAuth();
const firebaseRDB = getDatabase();
const firebaseStorage = getStorage();

function addToFirebaseRDB(root, data) {
  // writes data to root
  return push(ref(firebaseRDB, root), data);
}

function setFirebaseRDBNode(root, data) {
  return set(ref(firebaseRDB, root), data);
}

function updateFirebaseRDBNode(root, data) {
  return update(ref(firebaseRDB, root), data);
}

function readonceFromFirebaseRDB(root, ...queryConstraints) {
  // reads a node without subscribing it, returns a snapshot
  return get(query(ref(firebaseRDB, root), ...queryConstraints))
}

// ==============================================================================
// =================== FOR END USERS ============================================
// ==============================================================================

// =================== DB READ/WRITE ============================================
export function registerOTP(email, otp, expiry_time) {
  // registers an OTP for email registeration and verification
  return addToFirebaseRDB('otps/', { email, otp, expiry_time });
}

export function addEmailToMailingList(email) {
  // adds email to mailing list
  return addToFirebaseRDB('mailinglist/', email);
}

export function addFeedback(feedback, contactDetail, timeOfFeedback) {
  return addToFirebaseRDB('feedbacks/', {text: feedback, contact: contactDetail, time: timeOfFeedback});
}

export function addSubLease(subleasedetails) {
  return addToFirebaseRDB('subleases/', subleasedetails);
}

export function getRegisteredOTP(email) {
  return readonceFromFirebaseRDB('otps/', orderByChild('email'), equalTo(email))
}

export function getAllSubleases() {
  return readonceFromFirebaseRDB('subleases/', orderByChild('monthlyrent'))
}

export function getSubleaseDetails(listingid){
  return readonceFromFirebaseRDB('subleases/' + listingid)
}

export function getAllLandlords() {
  return readonceFromFirebaseRDB('landlords/')
}

export function saveLandlordDetails(landlorddetails){
  return addToFirebaseRDB('landlords/', landlorddetails);
}

export function getPropertyOptions(){
  return readonceFromFirebaseRDB('propertyoptions/');
}

export function updatePropertyOptions(newpropertyoptions){
  return setFirebaseRDBNode('propertyoptions/', newpropertyoptions);
}

export function getLeaseAvailability(leaseid){
  return readonceFromFirebaseRDB('subleases/' + leaseid + '/availabilityresponses/');
}

export function saveLeaseAvailability(leaseid, uuid, leaseavailabilitydata){
  return setFirebaseRDBNode('subleases/' + leaseid + '/availabilityresponses/' + uuid + '/', leaseavailabilitydata);
}

export function getCourseList(branch){
  return readonceFromFirebaseRDB('courselist/' + branch);
}

export function getAllCourseExchangeMappings(branch){
  return readonceFromFirebaseRDB('courseexchanges/' + branch);
}

export function addCourseExchangeMapping(newmapping, branch){
  return addToFirebaseRDB('courseexchanges/' + branch, newmapping);
}

export function getCourseMappingRef(branch){
  return ref(firebaseRDB, 'courseexchanges/' + branch);
}

export function getLoggedInUserInfo(uid){
  return readonceFromFirebaseRDB('userinfo/' + uid);
}

export function addUserInfo(uid, info){
  return setFirebaseRDBNode('userinfo/' + uid, info);
}

export function updateUserInfo(uid, info){
  return updateFirebaseRDBNode('userinfo/' + uid, info);
}

export function getLeasesByUserID(uid){
  return readonceFromFirebaseRDB('subleases/', orderByChild('addedby'), equalTo(uid));
}

// =================== AUTHENTICATION ===========================================

export function createUserAccount(email, password) {
  // creates firebase user with email and password
  return createUserWithEmailAndPassword(firebaseAuth, email, password);
}

export async function logoutUserAndDeleteSession() {
  // logout firebase user and delete session data
  try {
    await firebaseAuth.signOut();
    sessionStorage.removeItem("user-info");
    sessionStorage.removeItem("batch");
    sessionStorage.removeItem("branch");
    return true;
  } catch (err) {
    console.error(err);
  }
}

export async function loginUser(email, password) {
  return signInWithEmailAndPassword(firebaseAuth, email, password);
}

onAuthStateChanged(firebaseAuth, (user) => {
  // observer to check if the user is logged in
  sessionStorage.setItem("user-info", JSON.stringify(user));

  // get the user info from db
  if (user && user.hasOwnProperty('uid'))
    getLoggedInUserInfo(user.uid).then(snap => {
      if (snap.val()) {
        // data retrieved from the snap/db
        sessionStorage.setItem("branch", snap.val().branch);
        sessionStorage.setItem("batch", snap.val().batch);
        sessionStorage.setItem("whatsapp", snap.val().whatsapp);
        sessionStorage.setItem("phone", snap.val().phone);
        sessionStorage.setItem("linkedin", snap.val().linkedin);
        sessionStorage.setItem("facebook", snap.val().facebook);
        sessionStorage.setItem("instagram", snap.val().instagram);
        sessionStorage.setItem("name", snap.val().name);

        // info from user info stored by auth
        sessionStorage.setItem("email", user.email);
        sessionStorage.setItem("uid", user.uid);

        // data obtained from mappings
        sessionStorage.setItem("university", VALID_EMAIL_DOMAINS['@'+user.email.split('@')[1]] || 'Undefined');
      }
    });
});


export function resetPassword(email){
  // alert("Hey sorry! This feature is not yet available due to some restrictions from your university. We are speaking to them and soon they should allow us to send emails to your inbox. Till then you will have to connect with MAndeep Vratesh and get your account recreated.");
  return sendPasswordResetEmail(firebaseAuth, email)
  .then(() => {
    return true;
  })
  .catch((error) => {
    return error.code;
  });
}

// =================== STORAGE ================================================

export function uploadFilesToStorage(files, location){
  // accepts files, each file as blob and returns promises for all
  var uploadPromises = [];

  for (var i = 0; i < files.length; i++) {
    var file = files[i];
    var uploadLocationRef = storageRef(firebaseStorage, location + '/' + file.name); // TODO: remove opportunity of overriding

    uploadPromises.push(uploadBytes(uploadLocationRef, file));
  }
  
  return uploadPromises;
}

export function getDownloadURLFromRef(snapref){
  return getDownloadURL(snapref);
}

// =================== ANALYTICS ================================================

export function addTracker(screenName){
  if(HOSTNAME != "localhost"){
    logEvent(analytics, 'screen_view', { firebase_screen: screenName });
  }
}