import React from 'react';
import styles from './loading.module.css';

import Lottie from "lottie-react";
import loading from './129874-meditating-man.json';
import { Typography } from '@mui/material';

const LottieLoading = ({message}) => (
  <div className={styles.LottieLoading} data-testid="LottieLoading">
    <Lottie animationData={loading} loop={true} />
    <Typography component="h6" align='center'>
      Sit, Relax, We are almost there!
    </Typography>
    <Typography component="h6" align='center'>
      {message}
    </Typography>
  </div>
);

LottieLoading.propTypes = {};

LottieLoading.defaultProps = {};

export default LottieLoading;