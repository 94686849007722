import React, { useState } from 'react';
import {
  Button,
  Dialog,
  IconButton,
  DialogActions,
  DialogTitle,
  Divider
} from '@mui/material';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import ContactCard from '../contact-card/contact-card';

export default function ContactOptionsButton({ contactdetails, contactpersontype, checkLeaseAvailability, updateLeaseAvailability, startIcon }) {

  const [opencontactdialog, toggleOpenContactDialog] = useState(false);
  const updateAvailability = (avl) => {
    toggleOpenContactDialog(false);
    updateLeaseAvailability(avl);
  }

  return (
    <>
      <Button
        fullWidth
        startIcon={startIcon}
        variant="contained"
        sx={{ ml: 2 }}
        onClick={() => toggleOpenContactDialog(true)}
      >
        Contact {contactpersontype}
      </Button>
      <Dialog open={opencontactdialog} onClose={() => toggleOpenContactDialog(false)}>
        <DialogTitle>Contact {contactpersontype}</DialogTitle>

        <ContactCard contactdetails={contactdetails} />

        <Divider />

        {checkLeaseAvailability && (
          <DialogActions>
            Lease still available?
            <IconButton color="success" onClick={() => updateAvailability("YES")}>
              <CheckBoxRoundedIcon />
            </IconButton>
            <IconButton color="error" onClick={() => updateAvailability("NO")}>
              <DisabledByDefaultRoundedIcon />
            </IconButton>
          </DialogActions>)
        }
      </Dialog>
    </>
  );
}