import React from 'react';
// import styles from './lottie/under-construction.module.css';

import Lottie from "lottie-react";
import constructionAnimation from "./44190-under-construction.json";

const LottieUnderConstruction = () => (
  <Lottie animationData={constructionAnimation} loop={true} />
);

export default LottieUnderConstruction;
