import React from 'react';
import styles from './404.module.css';

import Lottie from "lottie-react";
import error404 from './404.json';

const Lottie404 = () => (
  <div className={styles.Lottie404} data-testid="Lottie404">
    <Lottie animationData={error404} loop={true} />
  </div>
);

Lottie404.propTypes = {};

Lottie404.defaultProps = {};

export default Lottie404;
