import React from 'react';
import DocumentMeta from 'react-document-meta';
import FilterSection from "./filter-section";
import QuestionCard from "../../components/question-card/question-card";
import RightSidebar from "./right-sidebar";
import {
  CssBaseline,
  Grid,
  Box,
  Typography,
  Container,
  createTheme, 
  ThemeProvider,
  Dialog
} from '@mui/material';
import LottieUnderConstruction from '../../components/lottie/under-construction/lottie/under-construction';

const theme = createTheme();
const meta = {
  title: 'HousingNet | QnA Board',
  description: 'Let\'s talk about what all we have to offer!',
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags,housing,questions,question,answer,answers,reditt'
    }
  }
};

const QnaBoardPage = () => {
  const [showunderconstruction, toggleUnderConstruction] = React.useState(true);

  return (<DocumentMeta {...meta}>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{color: "#0561B8FF"}} variant="h4">
                QnA Board
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FilterSection />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{fontSize: "16px", color: "#171A1FFF"}} variant="h4">
                32 results for 
                <span style={{ color: "#0561B8FF", fontWeight: "normal" }}>{"'keyword'"}</span>
              </Typography>              
            </Grid>
            <Grid item xs={12}>
              <QuestionCard />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Dialog onClick={() => toggleUnderConstruction(false)}
        open={showunderconstruction}
        // keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <LottieUnderConstruction />
        <Typography align="center" variant="h6" m={2}>This page is still under construction and things might not work as expected. Click anywhere to move ahead.</Typography>
      </Dialog>
    </ThemeProvider>
    </DocumentMeta>
  );
}

export default QnaBoardPage;
