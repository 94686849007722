// base url to current hosted version of the site
export const BASEURL = "https://housingnet.org";

// hostname
export const HOSTNAME = window.location.hostname;

// emailjs configs
export const EMAILJS_SERVICEID = 'service_29sxobu';
export const EMAILJS_TEMPLATEID_OTPVERIFICATIONMAIL = 'template_xvxlk2h';
export const EMAILJS_PUBLICKEY = 'dUDI4qmRhRAZh1owe';

// mapbox
export const MAPBOX_TOKEN = "pk.eyJ1IjoiaG91c2luZ25ldCIsImEiOiJjbGNvc2VkN2MwaGpzM25sYnQ2YWE4cXc0In0.lfKwJeQCv1J3RFtQNHaLUg";

// application constants
export const VALID_EMAIL_DOMAINS = {
    '@buffalo.edu': 'University at Buffalo',
    '@northeastern.edu': 'Northeastern University',
    '@binghamton.edu': 'Binghamton Univerity',
    '@muleriders.saumag.edu': 'Southern Arkansas University'
}

export const BLOCKED_EMAILS = [
    'miakh@buffalo.edu',
    'sidefit233@buffalo.edu',
    'juliar@buffalo.edu',
    'sgriffin@northeastern.edu'
]

export const FIREBASE_ERROR_ABSTRACTED = {
    // general errors
    'auth/network-request-failed': 'You seem to be offline, please retry after connecting.',

    // auth specific
    'auth/claims-too-large': 'Unidentified error, please contact mandeepv@buffalo.edu',
    'auth/email-already-exists': 'The email is already in use by an existing user.',
    'auth/id-token-expired': 'Your session has expired, please relogin.',
    'auth/id-token-revoked': 'Your session has expired, please relogin.',
    'auth/insufficient-permission': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/internal-error': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-argument': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-claims': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-continue-uri': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-creation-time': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-credential': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-disabled-field': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-display-name': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-dynamic-link-domain': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-email': 'Invalid email. Please use your university email.',
    'auth/invalid-email-verified': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-hash-algorithm': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-hash-block-size': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-hash-derived-key-length': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-hash-key': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-hash-memory-cost': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-hash-parallelization': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-hash-rounds': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-hash-salt-separator': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-id-token': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-last-sign-in-time': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-page-token': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-password': 'Incorrect username or password.',
    'auth/wrong-password': 'Incorrect username or password.',
    'auth/invalid-password-hash': 'Incorrect username or password.',
    'auth/invalid-password-salt': 'Incorrect username or password.',
    'auth/invalid-phone-number': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-photo-url': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-provider-data': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-provider-id': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-oauth-responsetype': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-session-cookie-duration': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-uid': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/invalid-user-import': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/maximum-user-count-exceeded': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/missing-android-pkg-name': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/missing-continue-uri': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/missing-hash-algorithm': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/missing-ios-bundle-id': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/missing-uid': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/missing-oauth-client-secret': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/operation-not-allowed': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/phone-number-already-exists': 'The provided Phone Number cannot be used. Please contact mandeepv@buffalo.edu',
    'auth/project-not-found': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/reserved-claims': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/session-cookie-expired': 'Your session has expired, please relogin.',
    'auth/session-cookie-revoked': 'Your session has expired, please relogin.',
    'auth/uid-already-exists': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/unauthorized-continue-uri': 'Uncategorized error, please contact mandeepv@buffalo.edu',
    'auth/user-not-found': 'No user found, maybe you need to sign up?',
}

// export const PROPERTYOPTIONS = {
//     possibleroominclusions: ['closet', 'mirror', 'mattress', 'study table', 'chair', 'lawn', 'trash', 'bed frame'],
//     possiblekitcheninclusions: ['dishwasher', 'refrigerator', 'microwave', 'oven', 'stove cook-top'],
//     streets: ['Tyler', 'Heath', 'Lisbon'],
//     roomprivacyoptions: ['Private', 'Shared', 'Shared /w 1+'],
//     genders: ['Male', 'Female', 'Any'],
//     roomcounts: [1, 2, 3, 4, 5, 6],
//     bathroomcounts: [1, 2, 3],
//     kitchencounts: [1, 2],
//     foodpreferences: ['Vegan', 'Vegetarian', 'Eggetarian', 'NVegetarian', 'Other'],
//     washerdryeroptions: ['CoinOperated', 'Included', 'Other'],
// }

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const getValuesFromFlags = (flags, vals) => {
    var checkedvalues = [];
    for (let i = 0; i < flags.length; i++) {
        if (flags[i])
            checkedvalues.push(vals[i]);
    }
    return checkedvalues;
}

export const AWSLAMBDA_CYCLEDETECTORURL = "https://xnll2zuekemsbpj6kmdibv7opi0wrgnn.lambda-url.us-west-2.on.aws/";