import React from 'react';
import styles from './roommates.module.css';

import Lottie from "lottie-react";
import roommatesAnimation from './90674-jumping.json';

const LottieRoommates = () => (
  <div className={styles.LottieRoommates} data-testid="LottieRoommates">
    <Lottie animationData={roommatesAnimation} loop={true} />
  </div>
);

LottieRoommates.propTypes = {};

LottieRoommates.defaultProps = {};

export default LottieRoommates;
