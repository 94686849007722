import React from 'react';
import DocumentMeta from 'react-document-meta';
import AboutUs from '../../components/about-us/about-us';
import Contributions from '../../components/contributions/contributions';
import Techstack from '../../components/techstack/techstack';
import Team from '../../components/team/team';

const meta = {
  title: 'HousingNet | AboutUs',
  description: 'Let\'s talk about what all we have to offer!',
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags,housing'
    }
  }
};

const AboutUsPage = () => (
  <DocumentMeta {...meta}>
    <AboutUs />
    <Team />
    <Contributions />
    <Techstack />
  </DocumentMeta>
);

export default AboutUsPage;
