import React from 'react';
import { Button, Grid, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Devansh from "../../assets/images/devansh.jpg";
import Ruthika from "../../assets/images/ruthika.jpg";
import Steffy from "../../assets/images/steffy.jpg";
import Shwetha from "../../assets/images/shwetha.jpg";
import Ozan from "../../assets/images/ozan.jpg";
import Mounika from "../../assets/images/mounika.jpg";
import Saran from "../../assets/images/saran.jpg";
import Avatar from '@mui/material/Avatar';

const theme = createTheme();

const Contributions = () => (
  <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" component="div">
          Contributions
        </Typography>
        <Typography variant="h7" component="div">
          This is a huge project and needed few hands while growing. Here are the good people who have spent time to make the project build faster. 
        </Typography>

        <Link href="mailto:mandeepv@buffalo.edu?subject=%5Bhousingnet.org%5D%20Interest%20to%20join&body=Hi%2C%0D%0AI%20wanted%20to%20join%20your%20team.%0D%0A%0D%0ARegards" target="_blank" rel="noopener noreferrer">
          <Button variant="contained" sx={{ margin: 5 }}>Join Us!</Button>
        </Link>

        <Grid
          container
          direction="row"
          justify="center"
          spacing={2}
        >
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Card>
              <Avatar alt="Devansh Shrivastava" src={Devansh} sx={{ width: 250, height: 250, margin: 1 }}/>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Devansh Shrivastava
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Technical Product Manager
                </Typography>
              </CardContent>
              <CardActions>
                <div style={{flex: '1 0 0'}} />
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="https://www.linkedin.com/in/devansh1994"
                >
                  Linkedin
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="mailto:devanshs@buffalo.edu"
                >
                  Email
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Card sx={{ maxWidth: 300 }}>
              <Avatar alt="Steffy Thampy" src={Steffy} sx={{ width: 250, height: 250, margin: 1 }}/>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Steffy Thampy
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Product Manager (Roommate Finder)
                </Typography>
              </CardContent>
              <CardActions>
                <div style={{flex: '1 0 0'}} />
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="https://www.linkedin.com/in/steffythampy"
                >
                  Linkedin
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="mailto:steffy.thampy@gmail.com"
                >
                  Email
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Card sx={{ maxWidth: 300 }}>
              <Avatar alt="Ruthika Juluri" src={Ruthika} sx={{ width: 250, height: 250, margin: 1 }}/>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Ruthika Juluri
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Developer (Family Plans)
                </Typography>
              </CardContent>
              <CardActions>
                <div style={{flex: '1 0 0'}} />
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="https://www.linkedin.com/in/ruthika-juluri/"
                >
                  Linkedin
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="mailto:ruthikaj@buffalo.edu"
                >
                  Email
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Card sx={{ maxWidth: 300 }}>
              <Avatar alt="Mounika Pallisetti" src={Mounika} sx={{ width: 250, height: 250, margin: 1 }}/>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Mounika Pallisetti
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Product Manager (Triper)
                </Typography>
              </CardContent>
              <CardActions>
                <div style={{flex: '1 0 0'}} />
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="https://www.linkedin.com/in/mounikapallisetti"
                >
                  Linkedin
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="mailto:mounika.palisetti1@gmail.com"
                >
                  Email
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Card sx={{ maxWidth: 300 }}>
              <Avatar alt="Swetha Palempalli" src={Shwetha} sx={{ width: 250, height: 250, margin: 1 }}/>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Swetha Palempalli
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Developer (Listings)
                </Typography>
              </CardContent>
              <CardActions>
                <div style={{flex: '1 0 0'}} />
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="https://www.linkedin.com/in/swethapalempalli"
                >
                  Linkedin
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="mailto:swethapa@buffalo.edu"
                >
                  Email
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Card sx={{ maxWidth: 300 }}>
              <Avatar alt="Ozan Guner" src={Ozan} sx={{ width: 250, height: 250, margin: 1 }}/>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Ozan Guner
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Cloud Deployment & Backend
                </Typography>
              </CardContent>
              <CardActions>
                <div style={{flex: '1 0 0'}} />
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="https://linkedin.com/in/ozan-gunner"
                >
                  Linkedin
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="mailto:gunerozan1992@gmail.com"
                >
                  Email
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Card sx={{ maxWidth: 300 }}>
              <Avatar alt="Saran Raj Veerasamy" src={Saran} sx={{ width: 250, height: 250, margin: 1 }}/>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Saran Raj Veerasamy
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Product design (Family Plans)
                </Typography>
              </CardContent>
              <CardActions>
                <div style={{flex: '1 0 0'}} />
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="https://www.linkedin.com/in/saran-raj-veerasamy"
                >
                  Linkedin
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  rel="noopener"
                  target="_blank"
                  href="mailto:saranrajv24@gmail.com"
                >
                  Email
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </ThemeProvider>
);

export default Contributions;
