import React from 'react';
import { Navigate} from 'react-router-dom';

const RequireAuth = ({children}) => {
        // check from local storage
    const userInfo = JSON.parse(sessionStorage.getItem("user-info"));

    // return children, otherwise navigate to login page
    return(
        <>
            { userInfo ? children : <Navigate to="/login" /> }
        </>
    );
}

export default RequireAuth
